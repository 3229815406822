import {
  KeyboardShortcutsContext,
  KeyboardShortcutsContextType,
  RegisteredShortcut,
} from "./KeyboardShortcutsContext";
import { KeyboardCode, ShortcutConfig } from "@/hooks/useKeyboardShortcut";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";

// Add this to track active shortcuts
const activeShortcuts = new Set<string>();
const globalShortcuts = new Array<RegisteredShortcut>();

const checkModifiers = (
  event: KeyboardEvent,
  config: ShortcutConfig
): boolean => {
  const modifiers = {
    ctrlKey: Boolean(config.ctrlKey),
    altKey: Boolean(config.altKey),
    shiftKey: Boolean(config.shiftKey),
    metaKey: Boolean(config.metaKey),
  };

  return (
    event.ctrlKey === modifiers.ctrlKey &&
    event.altKey === modifiers.altKey &&
    event.shiftKey === modifiers.shiftKey &&
    event.metaKey === modifiers.metaKey
  );
};

const checkCode = (event: KeyboardEvent, config: ShortcutConfig): boolean => {
  if (Array.isArray(config.code)) {
    return config.code.includes(event.code as KeyboardCode);
  }

  return event.code === config.code;
};

// TODO: Revise this when we need to support multiple shortcuts in a row...
const handleKeyDown = (event: KeyboardEvent) => {
  // Cancel shortcut if key is being held down
  if (event.repeat) {
    return null;
  }

  const isTextInput =
    event.target instanceof HTMLTextAreaElement ||
    (event.target instanceof HTMLInputElement &&
      (!event.target.type || event.target.type === "text")) ||
    (event.target as HTMLElement)?.isContentEditable;

  if (isTextInput) {
    return null;
  }

  for (let i = globalShortcuts.length - 1; i >= 0; i--) {
    const { id, config, disabled, setIsPressed, onKeyDown } =
      globalShortcuts[i];

    if (!config || disabled) continue;

    if (checkCode(event, config) && checkModifiers(event, config)) {
      event.preventDefault();
      setIsPressed?.(true);
      onKeyDown?.();

      // Store the shortcut ID and its config for keyup matching
      activeShortcuts.add(id);

      break; // Stop after first matching shortcut
    }
  }
};

const handleKeyUp = (event: KeyboardEvent) => {
  for (let i = globalShortcuts.length - 1; i >= 0; i--) {
    const { id, config, disabled, setIsPressed, onKeyUp } = globalShortcuts[i];

    if (!config || disabled) continue;

    // Only check the code, not modifiers, and verify it was previously activated
    if (activeShortcuts.has(id)) {
      event.preventDefault();
      event.stopPropagation();
      setIsPressed?.(false);
      onKeyUp?.();

      activeShortcuts.delete(id);

      break; // Stop after first matching shortcut
    }
  }
};

export const KeyboardShortcutsProvider = ({ children }: PropsWithChildren) => {
  const registerShortcut = useCallback((shortcut: RegisteredShortcut) => {
    globalShortcuts.push(shortcut);

    return () => {
      globalShortcuts.splice(globalShortcuts.indexOf(shortcut), 1);
    };
  }, []);

  const registerShortcuts = useCallback((shortcuts: RegisteredShortcut[]) => {
    shortcuts.forEach(s => globalShortcuts.push(s));

    return () => {
      shortcuts.forEach(s =>
        globalShortcuts.splice(globalShortcuts.indexOf(s), 1)
      );
    };
  }, []);

  useEffect(() => {
    const handleKeyDownWrapper = (event: KeyboardEvent) => handleKeyDown(event);

    const handleKeyUpWrapper = (event: KeyboardEvent) => handleKeyUp(event);

    document.addEventListener("keydown", handleKeyDownWrapper, {
      passive: false,
    });
    document.addEventListener("keyup", handleKeyUpWrapper, {
      passive: false,
    });

    return () => {
      document.removeEventListener("keydown", handleKeyDownWrapper);
      document.removeEventListener("keyup", handleKeyUpWrapper);
      globalShortcuts.length = 0;
      activeShortcuts.clear();
    };
  }, []);

  const value = useMemo(
    () =>
      ({
        registerShortcut,
        registerShortcuts,
      }) satisfies KeyboardShortcutsContextType,
    [registerShortcut, registerShortcuts]
  );

  return (
    <KeyboardShortcutsContext.Provider value={value}>
      {children}
    </KeyboardShortcutsContext.Provider>
  );
};
