import { getRole } from "@/services/api/role/actions";
import { useQuery } from "@tanstack/react-query";

export const useRoleQuery = (roleId: string | number | undefined) => {
  return useQuery({
    queryKey: ["role", "detail", `${roleId}`],
    queryFn: () => getRole(String(roleId!)),
    enabled: !!roleId,
  });
};
