import { AxiosResponse } from "axios";

// For Make Log on Develop Mode
export const logOnDev = (...message: unknown[]) => {
  if (
    process.env.NODE_ENV === "development" ||
    localStorage.getItem("__necta-debug") === "true"
  ) {
    console.log(...message);
  }
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { method, url } = response.config;
  const { status } = response;
  // Set Loading End Here
  // Handle Response Data Here
  // Error Handling When Return Success with Error Code Here
  logOnDev(
    `🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`,
    response
  );

  return response;
};

export default onResponse;
