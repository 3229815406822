import passwordResetRoutes from "./password-reset";
import resetPasswordRoutes from "./reset-password";
import LoginPage from "@/pages/authentication/login";
import RegisterPage from "@/pages/authentication/register";
import RegisterSuccessPage from "@/pages/authentication/register/success";
import VerificationPage from "@/pages/authentication/verify";
import { LoaderFunctionArgs, Route } from "react-router-dom";

type AuthenticationRoutesProps = {
  loginLoader: ({
    request,
  }: LoaderFunctionArgs<unknown>) => Promise<Response | null>;
};
const authenticationRoutes = ({ loginLoader }: AuthenticationRoutesProps) => {
  return (
    <>
      <Route path="/login" element={<LoginPage />} loader={loginLoader} />

      <Route path="/register" loader={loginLoader}>
        <Route index element={<RegisterPage />} />
        <Route path="success" element={<RegisterSuccessPage />} />
      </Route>

      {passwordResetRoutes({ loginLoader })}
      {resetPasswordRoutes({ loginLoader })}

      <Route
        path="/verify/:uidb64/:token"
        element={<VerificationPage />}
        loader={loginLoader}
      />
    </>
  );
};
export default authenticationRoutes;
