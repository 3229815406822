import { PusherUpdateEventData } from "../../types";
import { baseOrganisationChannelUpdateFn } from "./base-organisation-channel-update-fn";
import { logOnDev } from "@/services/axios/interceptors/response";
import { QueryClient } from "@tanstack/react-query";

export const organisationChannelUpdateFn = (
  queryClient: QueryClient,
  data: PusherUpdateEventData
) => {
  logOnDev("[‼️][UPDATE EVENT]", data);

  baseOrganisationChannelUpdateFn(queryClient, data);
};
