import Necta from "@/components/extended/necta";
import Logo from "@/components/icons/logo";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RegisterSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const notValid = location.state === null || !("first_name" in location.state);

  useEffect(() => {
    if (notValid) navigate("/register");

    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  if (notValid) return <></>;

  return (
    <div className="relative container grid h-screen flex-col items-center justify-center">
      <div className="p-8">
        <div className="mx-auto flex flex-col justify-center space-y-6 align-middle">
          <span className="flex w-full justify-center">
            <Logo className="h-14 w-14" />
          </span>
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Registration Successful
            </h1>
            <p className="text-muted-foreground text-sm">
              Thank you {location.state.first_name}, you have successfully
              created a <Necta /> account. Please verify your email address
              before signing in.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccessPage;
