export const enum RoleLevel {
  ORGANISATIONAL_ADMIN = 0,
  ADMIN = 100,
  TEAM_LEADER = 200,
  OFFICE = 300,
  WORKER = 400,
}

export const enum RoleLevelBoundary {
  ORGANISATIONAL_ADMIN = 99,
  ADMIN = 199,
  TEAM_LEADER = 299,
  OFFICE = 399,
  WORKER = 499,
}

export const ORGANISATION_CODE_LENGTH = 6;

export const DATE_FORMAT = "YYYY-MM-DD";

export const ACCESS_TOKEN_NAME = "accounts.necta.access-token";
export const REFRESH_TOKEN_NAME = "accounts.necta.refresh-token";
