import { ClearanceLevel } from "@/constants/clearance-levels";
import { SidebarNavigationSectionType } from "@/types/navigation";
import {
  MapPin,
  Package,
  BookUser,
  LeafyGreen,
  Boxes,
  Container,
  Users,
  UsersRound,
  Table2,
} from "lucide-react";

const tables: SidebarNavigationSectionType = {
  type: "section",
  id: "tables",
  title: "Tables",
  href: "",
  icon: Table2,
  items: [
    {
      id: "area-codes",
      title: "Area Codes",
      type: "item",
      href: "/area-codes",
      icon: MapPin,
    },
    {
      id: "customers",
      title: "Customers",
      type: "item",
      href: "/customers",
      icon: BookUser,
      clearanceLevel: ClearanceLevel.CUSTOMERS,
    },
    {
      id: "orders",
      title: "Orders",
      type: "item",
      href: "/orders",
      icon: Package,
    },
    {
      id: "produce",
      title: "Produce",
      type: "item",
      href: "/produce",
      icon: LeafyGreen,
    },
    {
      id: "stock",
      title: "Stock",
      type: "item",
      href: "/stock",
      icon: Boxes,
      clearanceLevel: ClearanceLevel.STOCK,
    },
    {
      id: "suppliers",
      title: "Suppliers",
      type: "item",
      href: "/suppliers",
      icon: Container,
    },
    {
      id: "teams",
      title: "Teams",
      type: "item",
      href: "/teams",
      icon: UsersRound,
      clearanceLevel: ClearanceLevel.TEAMS,
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      href: "/users",
      icon: Users,
      clearanceLevel: ClearanceLevel.USERS,
    },
  ],
};

export default tables;
