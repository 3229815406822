import AuthenticationWrapper from "../authentication-wrapper";
import { RegisterForm } from "./register-form";
import Necta from "@/components/extended/necta";
import { Link } from "@/components/ui/link";

export default function RegisterPage() {
  const description = (
    <span>
      Already have an account with us? <Link to="/login">Sign in.</Link>
    </span>
  );
  return (
    <AuthenticationWrapper
      title={
        <span>
          Welcome to <Necta />, register now
        </span>
      }
      description={description}
      AuthenticationFormComponent={RegisterForm}
    ></AuthenticationWrapper>
  );
}
