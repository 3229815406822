import axiosClient from "..";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../../api/constants";
import { REFRESH_TOKEN_URL } from "../../api/paths";
import { logOnDev } from "./response";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

// Utility function to remove tokens and redirect to login
const clearTokensAndRedirect = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  // Redirect to LOGIN_URL if necessary
  // window.location.href = LOGIN_URL;
};

export class RefreshTokenError extends Error {} // TODO: move to proper place

// Handle refreshing of tokens
const handleRefresh = async (originalRequest: InternalAxiosRequestConfig) => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
  if (!refreshToken) {
    logOnDev("🚨 [API] Error: No refresh token available.");
    clearTokensAndRedirect();
    return Promise.reject(new RefreshTokenError("Refresh token not available"));
  }

  // Try and refresh the tokens.
  const refreshTokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

  // exp date in token is expressed in seconds, while now() returns milliseconds:
  const now = Math.ceil(Date.now() / 1000);

  if (now >= refreshTokenParts.exp) {
    logOnDev("Error: Refresh token is expired", refreshTokenParts.exp, now);
    return Promise.reject(new RefreshTokenError("Refresh token has expired"));
  }
  return axiosClient
    .post(REFRESH_TOKEN_URL, { refresh: refreshToken })
    .then(response => {
      localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access);
      localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refresh);

      axiosClient.defaults.headers.Authorization =
        "JWT " + response.data.access;
      originalRequest.headers!.Authorization = "JWT " + response.data.access;

      return axiosClient(originalRequest);
    })
    .catch(err => {
      logOnDev("[handleRefresh] ERROR:", err);
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem(REFRESH_TOKEN_NAME);
      return Promise.reject(err);
    });
};

// Main error handler
// Removed: : Promise<AxiosError>
const onErrorResponse = async (
  error: AxiosError | Error
): Promise<AxiosResponse> => {
  if (!axios.isAxiosError(error)) {
    logOnDev(`🚨 [API] | Non-Axios Error: ${error.message}`);
    return Promise.reject(error);
  }

  const { config, response } = error;
  const { method, url } = config as InternalAxiosRequestConfig;
  const statusCode = response?.status;
  const statusText = response?.statusText;

  logOnDev(
    `🚨 [API] ${method?.toUpperCase()} ${url} | Error: ${statusCode} ${statusText}`,
    error
  );

  if (response === undefined) {
    logOnDev("Undefined response ERROR:", error);
    return Promise.reject(error);
  }

  if (config === undefined) {
    logOnDev("Undefined config ERROR:", error);
    return Promise.reject(error);
  }

  switch (statusCode) {
    case 401:
      if (url === REFRESH_TOKEN_URL) {
        logOnDev("🚨 [API] Refresh token invalid, redirecting to login...");
        clearTokensAndRedirect();
      } else {
        // Attempt to refresh the token
        return handleRefresh(config);
      }
      break;
    case 403:
      // Handle "Permission Denied" globally if needed
      break;
    case 404:
      // Handle "Not Found" globally if needed
      break;
    case 500:
      // Handle "Server Error" globally if needed
      break;
    default:
      // Handle other errors globally if needed
      break;
  }

  return Promise.reject(error);
};

export default onErrorResponse;
