import Necta from "./extended/necta";
import { UserNavigation } from "./layout/header/user-navigation";
import PusherStatus from "./layout/header/user-navigation/pusher-status";
import { SidebarNavigation } from "./layout/sidebar/sidebar-navigation";
import sidebarMenuItems from "./layout/sidebar/sidebar-navigation/menu-items";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useRoleQuery } from "@/pages/edit/role/queries";
import { RoleLevel } from "@/services/api/constants";
import { useAuthenticationAuthenticatedContext } from "@/services/context/authentication/authentication-context";
import * as React from "react";

const RoleName = ({ roleId }: { roleId: number }) => {
  const { data: role } = useRoleQuery(roleId);

  if (!role) return null;

  if (role.level === RoleLevel.WORKER) {
    return <span className="truncate text-xs">Farm hand</span>;
  }

  return <span className="truncate text-xs">{role?.name}</span>;
};

const Header = () => {
  const { user } = useAuthenticationAuthenticatedContext();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton size="lg">
          <div className="flex aspect-square size-8 min-w-auto items-center justify-center rounded-md bg-green-200">
            <span>🌿</span>
          </div>

          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-medium">
              {user.organisation.name}
            </span>
            <RoleName roleId={user.role} />
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

const NectStatus = () => {
  return (
    <SidebarMenu>
      <SidebarMenuItem className="mx-auto flex! w-fit flex-row items-center justify-center gap-2">
        <SidebarMenuButton className="flex min-w-fit items-center justify-center gap-2 text-xs">
          <Necta className="text-md m-2 flex w-fit cursor-default justify-center opacity-30 sm:m-0" />
          <PusherStatus />
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
};
export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar variant="inset" collapsible="icon" {...props}>
      <SidebarHeader>
        <Header />
      </SidebarHeader>
      <SidebarContent>
        <SidebarNavigation items={sidebarMenuItems} />
      </SidebarContent>
      <SidebarFooter>
        <UserNavigation />
        <NectStatus />
      </SidebarFooter>
    </Sidebar>
  );
}
