// Axios
import axiosClient from "../../axios";
// Imports
import {
  apiURL,
  ME_URL,
  USER_URL,
  getUserURL,
  GET_ASSIGNABLE_ROLES_URL,
} from "../paths";
import { ListOptions } from "../types/list";
import { PaginationResponse } from "@/types/table";
import { UserSelf, UserFormRequest, User, UsersTable } from "@/types/user";
import { RolesTable } from "@/types/user/role";

export const getUserInfo = async (): Promise<UserSelf> => {
  const response = await axiosClient.get(apiURL(ME_URL));
  return response.data;
};

export const removeUser = async (userId: string): Promise<void> => {
  const response = await axiosClient.delete(getUserURL(userId));
  return response.data;
};

export const listUsers = async (
  options?: ListOptions
): Promise<PaginationResponse<UsersTable>> => {
  const response = await axiosClient.get(apiURL(USER_URL), options);
  return response.data;
};

export const getUser = async (userId: string): Promise<User> => {
  const response = await axiosClient.get(getUserURL(userId));
  return response.data;
};

export const updateUser = async (
  userId: string,
  userRequest: UserFormRequest
): Promise<User> => {
  const response = await axiosClient.put(getUserURL(userId), userRequest);
  return response.data;
};

export const updateMe = async (
  userId: string,
  userRequest: Partial<UserFormRequest>
): Promise<UserSelf> => {
  const response = await axiosClient.patch(getUserURL(userId), userRequest);
  return response.data;
};

export const listAssignableUserRoles = async (
  options?: ListOptions
): Promise<PaginationResponse<RolesTable>> => {
  const response = await axiosClient.get(
    apiURL(GET_ASSIGNABLE_ROLES_URL),
    options
  );
  return response.data;
};
