import { AppRouteType } from "./types";
import Loadable from "@/components/extended/loadable/loadable";
import { ClearanceLevel } from "@/constants/clearance-levels";
import { TableURL } from "@/constants/urls";
import { lazy } from "react";

const baseStockRoutes: Omit<AppRouteType, "element"> = {
  name: "Stock",
  pathname: TableURL.STOCK,
  clearanceLevel: ClearanceLevel.STOCK,
  type: "item",
  children: [
    {
      name: "Edit Stock",
      pathname: ":stockId",
      type: "item",
      element: Loadable(lazy(() => import("@/pages/edit/stock"))),
    },
  ],
} as const;

export const defaultStockRoutes = {
  ...baseStockRoutes,
  element: Loadable(lazy(() => import("@/pages/tables/stock"))),
} as AppRouteType;

export const currentStockRoutes: AppRouteType = {
  ...baseStockRoutes,
  element: Loadable(lazy(() => import("@/pages/tables/current/stock"))),
} as AppRouteType;
