import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { Link } from "@/components/ui/link";
import { Switch } from "@/components/ui/switch";
import { ShareHash } from "@/lib/utils/generate-share-link";
import { registerAdmin, registerUser } from "@/services/api/auth/actions";
import { ORGANISATION_CODE_LENGTH } from "@/services/api/constants";
import { logOnDev } from "@/services/axios/interceptors/response";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { LoaderCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

type ErrorData = {
  [key: string]: string;
};

const baseSchema = z.object({
  first_name: z.string().min(1, "First name must not be empty."),
  last_name: z.string().min(1, "Last name must not be empty."),
  email: z.string().min(1, "Email must not be empty."),
  password: z.string().min(1, "Password must not be empty."),
});

const adminSchema = z.object({
  registerAsOrganisation: z.literal(true),
  organisation_name: z.string(),
});

const userSchema = z.object({
  registerAsOrganisation: z.literal(false),
  organisation_code: z
    .string()
    .length(ORGANISATION_CODE_LENGTH, "Please enter a valid code."),
});

const fullSchema = z
  .discriminatedUnion("registerAsOrganisation", [adminSchema, userSchema])
  .and(baseSchema);

export type RegisterFormType = z.infer<typeof fullSchema>;

type DefaultValuesType = z.infer<typeof fullSchema>;

export function RegisterForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // TODO: implement when more orgs can join
  // const defaultRegisterAsOrganisation =
  //   searchParams.get("registerAsOrganisation") === "true";
  const [registerAsOrganisation, setRegisterAsOrganisation] = useState<boolean>(
    false //defaultRegisterAsOrganisation
  );

  const {
    inviterName,
    organisationCode,
    organisationName,
  }: Nullable<ShareHash> = location.state ?? {
    inviterName: null,
    organisationCode: null,
    organisationName: null,
  };
  const hasBeenInvited =
    !!inviterName && !!organisationCode && !!organisationName;

  useEffect(() => {
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  const defaultValues: DefaultValuesType = {
    first_name: "",
    last_name: "",
    email: searchParams.get("email") ?? "",
    password: "",
    organisation_code: "",
    organisation_name: "",
    registerAsOrganisation: registerAsOrganisation,
  };

  const form = useForm<RegisterFormType>({
    resolver: zodResolver(fullSchema),
    defaultValues,
  });

  const { formState } = form;

  const { isSubmitting } = formState;

  const handleOnSubmit = async (data: RegisterFormType) => {
    try {
      if (registerAsOrganisation) {
        await registerAdmin(data);
      } else {
        await registerUser(data);
      }

      navigate("success", { state: { first_name: data.first_name.trim() } });
    } catch (err) {
      Sentry.captureMessage("Registration Error", {
        extra: { err },
      });
      const axiosError = err as AxiosError; // Infer the type
      if (axiosError.response?.data) {
        const errorData = axiosError.response.data;

        if (Array.isArray(errorData)) {
          // Handle array of strings
          form.setError("root", { message: errorData.join("\n") });
        } else if (typeof errorData === "object") {
          // Handle object with field keys
          const errorDataObj = errorData as ErrorData;
          for (const fieldKey in errorData) {
            if (Object.hasOwn(errorData, fieldKey)) {
              const errorMessage = errorDataObj[fieldKey];
              form.setError(fieldKey as keyof RegisterFormType, {
                message: errorMessage,
              });
            }
          }
        } else {
          form.setError("root", {
            message: "An error occurred, please try again later",
          }); // Fallback error message
        }
        logOnDev(axiosError.response.data);
      } else {
        form.setError("root", {
          message: "An error occurred, please try again later",
        });
      }
    }
  };

  return (
    <Form {...form}>
      {hasBeenInvited && (
        <div className="pb-4 text-balance text-gray-700">
          Hi there! <span className="font-logo">{inviterName}</span> has invited
          you to join <span className="font-logo">{organisationName}</span>.
          Fill in your details to join the team.
        </div>
      )}
      <form
        onSubmit={form.handleSubmit(handleOnSubmit, e => {
          logOnDev(e);
        })}
        className="max-w-lg space-y-8"
      >
        <FormField
          key="first_name"
          control={form.control}
          name="first_name"
          render={({ field: formField }) => {
            return (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input
                    {...formField}
                    type="text"
                    autoComplete="given-name"
                    autoCapitalize="words"
                    disabled={isSubmitting}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            );
          }}
        />

        <FormField
          key="last_name"
          control={form.control}
          name="last_name"
          render={({ field: formField }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input
                  {...formField}
                  type="text"
                  autoComplete="family-name"
                  autoCapitalize="words"
                  disabled={isSubmitting}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          key="email"
          control={form.control}
          name="email"
          render={({ field: formField }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  {...formField}
                  type="email"
                  autoComplete="email"
                  disabled={isSubmitting}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          key="password"
          control={form.control}
          name="password"
          render={({ field: formField }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  {...formField}
                  id="new-password"
                  type="password"
                  autoComplete="new-password"
                  disabled={isSubmitting}
                  className="leading-[0]"
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          key="registerAsOrganisation"
          control={form.control}
          name="registerAsOrganisation"
          render={({ field: formField }) => (
            <FormItem hidden={hasBeenInvited}>
              <div className="flex items-center justify-between align-middle">
                <FormLabel>Register as an organisation</FormLabel>
                <FormControl>
                  <Switch
                    {...formField}
                    value={registerAsOrganisation ? "on" : "off"}
                    checked={registerAsOrganisation}
                    onCheckedChange={checked => {
                      form.setValue("registerAsOrganisation", checked);
                      setRegisterAsOrganisation(checked);
                    }}
                    disabled={true || isSubmitting} // TODO: remove true
                  />
                </FormControl>
              </div>

              <FormMessage />
              <FormDescription>
                We are currently in a closed beta. Please contact{" "}
                <Link to="mailto:support@necta.app">support</Link> for more
                information.
              </FormDescription>
            </FormItem>
          )}
        />

        {registerAsOrganisation ? (
          <FormField
            key="organisation_name"
            control={form.control}
            name="organisation_name"
            render={({ field: formField }) => (
              <FormItem>
                <FormLabel>Organisation Name</FormLabel>
                <FormControl>
                  <Input {...formField} disabled={isSubmitting} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        ) : (
          <FormField
            key="organisation_code"
            control={form.control}
            name="organisation_code"
            render={({ field: formField }) => (
              <FormItem>
                <FormLabel>Organisation Code</FormLabel>
                <FormControl>
                  <InputOTP
                    maxLength={6}
                    {...formField}
                    className="flex w-full justify-between"
                  >
                    <InputOTPGroup className="flex-1">
                      <InputOTPSlot index={0} />
                    </InputOTPGroup>
                    <InputOTPGroup className="flex-1">
                      <InputOTPSlot index={1} />
                    </InputOTPGroup>
                    <InputOTPGroup className="flex-1">
                      <InputOTPSlot index={2} />
                    </InputOTPGroup>
                    <InputOTPGroup className="flex-1">
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                    <InputOTPGroup className="flex-1">
                      <InputOTPSlot index={4} />
                    </InputOTPGroup>
                    <InputOTPGroup className="flex-1">
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField name="root" render={() => <FormMessage />}></FormField>

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting && (
            <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
          )}
          Register as {registerAsOrganisation ? "an organisation" : "a user"}
        </Button>
      </form>
    </Form>
  );
}
