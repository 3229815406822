import { PusherDeleteEventData } from "../../types";
import { logOnDev } from "@/services/axios/interceptors/response";
import { QueryClient } from "@tanstack/react-query";

export const organisationChannelDeleteFn = async (
  queryClient: QueryClient,
  data: PusherDeleteEventData
) => {
  logOnDev("[‼️][DELETE EVENT]", data);

  const listQueryKey = [...data.entity, "list", ...(data.filters ?? [])].filter(
    Boolean
  );

  // Remove all queries for the deleted items
  data.ids.forEach(id => {
    queryClient.removeQueries({
      queryKey: [...data.entity, "detail", id.toString()],
    });
  });

  // Invalidate lists
  await queryClient.invalidateQueries({ queryKey: listQueryKey });
};
