import { baseNavigationShortcutConfig } from ".";
import { ShortcutConfig } from "@/hooks/useKeyboardShortcut";

export const dashboardNavigationShortcutConfig: ShortcutConfig = {
  ...baseNavigationShortcutConfig,
  code: "KeyD",
} as const;

export const ordersNavigationShortcutConfig: ShortcutConfig = {
  ...baseNavigationShortcutConfig,
  code: "KeyO",
} as const;

export const stockNavigationShortcutConfig: ShortcutConfig = {
  ...baseNavigationShortcutConfig,
  code: "KeyS",
} as const;

export const settingsNavigationShortcutConfig: ShortcutConfig = {
  ...baseNavigationShortcutConfig,
  code: "KeyT",
} as const;
