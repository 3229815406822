// export const CUSTOMERS = "customers";
// export const ORDERS = "orders";
// export const PRODUCE = "produce";
// export const STOCK = "stock";
// export const SUPPLIERS = "suppliers";

export enum TableURL {
  HOME = "/",
  AREA_CODES = "area-codes",
  CURRENT = "current",
  CUSTOMERS = "customers",
  PRODUCE = "produce",
  ORDERS = "orders",
  ROLES = "roles",
  STOCK = "stock",
  SUPPLIERS = "suppliers",
  TEAMS = "teams",
  USERS = "users",
}

export enum ROUTES {
  ABOUT = "about",
  COMPOSITE_COMPONENT = "composite-component",
  DASHBOARD = "",
  HELP = "help",
  LOGIN = "login",
  PRODUCE_QUANTITY_SUFFIX = "quantity-suffix",
  PRODUCE_VARIETY = "variety",
  ORDER_ITEM = "order-item",
  ORDER_ITEM_STOCK_LINK = "stock-link",
  SETTINGS = "settings",
  REGISTER = "register",
}
