// Axios
import axiosClient from "../../axios";
import { bulkDelete } from "../base/actions";
// Imports
import {
  apiURL,
  ORDER_URL,
  CURRENT_ORDERS_URL,
  getOrderURL,
  getOrderItemsURL,
  ORDER_ITEM_URL,
  getOrderItemURL,
  ORDER_ITEM_STOCK_LINK_URL,
  getOrderItemStockLinkURL,
  getOrderItemAssignedStockURL,
  getOrderItemAvailableStockURL,
  getOrderItemStockLinksURL,
  getOrderItemStockLinkUsedStockHistoryURL,
  ZUPPLY_SKU_MAPPINGS_URL,
} from "../paths";
import { ListOptions } from "../types/list";
import {
  EditOrder,
  Order,
  OrdersTable,
  OrdersUploadPreview,
  OrdersUploadPreviewCustomerError,
} from "@/types/order";
import { OrderItemRequest, OrderItem } from "@/types/order/order-item";
import {
  OrderItemStockLinkRequest,
  OrderItemStockLink,
  OrderItemStockLinkCreateResponse,
} from "@/types/order/order-item/order-item-stock-link";
import { Stock } from "@/types/stock";
import { PaginationResponse } from "@/types/table";
import { ZupplySKUMappings } from "@/types/zupply-sku-mappings";

/* ---------- ORDER ---------- */
export const createOrder = async (orderRequest: EditOrder): Promise<Order> => {
  const response = await axiosClient.post(apiURL(ORDER_URL), orderRequest);
  return response.data;
};

export const removeOrder = async (orderId: string): Promise<Order> => {
  const response = await axiosClient.delete(getOrderURL(orderId));
  return response.data;
};

export const bulkDeleteOrder = async (
  params: Omit<Parameters<typeof bulkDelete>[0], "context">
): Promise<void> => {
  return bulkDelete({ context: ORDER_URL, ...params });
};

export const listOrders = async (
  options?: ListOptions
): Promise<PaginationResponse<OrdersTable>> => {
  const response = await axiosClient.get(apiURL(ORDER_URL), {
    ...options,
  });
  return response.data;
};

export const listCurrentOrders = async (
  options?: ListOptions
): Promise<PaginationResponse<OrdersTable>> => {
  const response = await axiosClient.get(apiURL(CURRENT_ORDERS_URL), {
    ...options,
  });
  return response.data;
};

export const getOrder = async (orderId: string): Promise<Order> => {
  const response = await axiosClient.get(getOrderURL(orderId));
  return response.data;
};

export const updateOrder = async (
  orderId: string,
  orderRequest: EditOrder
): Promise<Order> => {
  const response = await axiosClient.put(getOrderURL(orderId), orderRequest);
  return response.data;
};

export const partialUpdateOrder = async (
  orderId: string,
  orderRequest: Partial<EditOrder>
): Promise<Order> => {
  const response = await axiosClient.patch(getOrderURL(orderId), orderRequest);
  return response.data;
};

export const markOrderCompletion = async (
  orderId: string,
  completionDate: string | null
): Promise<OrderItem[]> => {
  const response = await axiosClient.patch(getOrderURL(orderId), {
    date_completed: completionDate,
  });
  return response.data;
};

export const getOrderItems = async (
  orderId: string,
  options?: ListOptions
): Promise<PaginationResponse<OrderItem>> => {
  const response = await axiosClient.get(getOrderItemsURL(orderId), options);
  return response.data;
};

/* ---------- ORDER ITEM ---------- */
export const createOrderItem = async (
  orderItemRequest: OrderItemRequest
): Promise<OrderItem> => {
  const response = await axiosClient.post(
    apiURL(ORDER_ITEM_URL),
    orderItemRequest
  );
  return response.data;
};

export const removeOrderItem = async (
  orderItemId: string
): Promise<OrderItem> => {
  const response = await axiosClient.delete(getOrderItemURL(orderItemId));
  return response.data;
};

export const bulkDeleteOrderItem = async (
  params: Omit<Parameters<typeof bulkDelete>[0], "context">
): Promise<void> => {
  return bulkDelete({ context: ORDER_ITEM_URL, ...params });
};

export const listOrderItems = async (
  options?: ListOptions
): Promise<PaginationResponse<OrderItem>> => {
  const response = await axiosClient.get(apiURL(ORDER_ITEM_URL), options);
  return response.data;
};

export const getOrderItem = async (orderItemId: string): Promise<OrderItem> => {
  const response = await axiosClient.get(getOrderItemURL(orderItemId));
  return response.data;
};

export const updateOrderItem = async (
  orderItemId: string,
  orderItemRequest: OrderItemRequest
): Promise<OrderItem> => {
  const response = await axiosClient.put(
    getOrderItemURL(orderItemId),
    orderItemRequest
  );
  return response.data;
};

export const partialUpdateOrderItem = async (
  orderItemId: string,
  orderItemRequest: Partial<OrderItemRequest>
): Promise<OrderItem> => {
  const response = await axiosClient.patch(
    getOrderItemURL(orderItemId),
    orderItemRequest
  );
  return response.data;
};

// TODO: add options
export const getOrderItemAssignedStock = async (
  orderItemId: string
): Promise<PaginationResponse<Stock>> => {
  const response = await axiosClient.get(
    getOrderItemAssignedStockURL(orderItemId)
  );
  return response.data;
};

export const getOrderItemAvailableStock = async (
  orderItemId: string,
  options?: ListOptions
): Promise<PaginationResponse<Stock>> => {
  const response = await axiosClient.get(
    getOrderItemAvailableStockURL(orderItemId),
    options
  );
  return response.data;
};

export const getOrderItemStockLinks = async (
  orderItemId: string,
  options?: ListOptions
): Promise<PaginationResponse<OrderItemStockLink>> => {
  const response = await axiosClient.get(
    getOrderItemStockLinksURL(orderItemId),
    options
  );
  return response.data;
};

/* ---------- ORDER ITEM STOCK LINK ---------- */
export const createOrderItemStockLink = async (
  orderItemRequest: OrderItemStockLinkRequest
): Promise<OrderItemStockLinkCreateResponse> => {
  const response = await axiosClient.post(
    apiURL(ORDER_ITEM_STOCK_LINK_URL),
    orderItemRequest
  );
  return response.data;
};

export const removeOrderItemStockLink = async (
  orderItemId: string
): Promise<void> => {
  const response = await axiosClient.delete(
    getOrderItemStockLinkURL(orderItemId)
  );
  return response.data;
};

export const bulkDeleteOrderItemStockLink = async (
  params: Omit<Parameters<typeof bulkDelete>[0], "context">
): Promise<void> => {
  return bulkDelete({ context: ORDER_ITEM_STOCK_LINK_URL, ...params });
};

export const listOrderItemStockLinks = async (
  options?: ListOptions
): Promise<PaginationResponse<OrderItemStockLink>> => {
  const response = await axiosClient.get(apiURL(ORDER_ITEM_STOCK_LINK_URL), {
    ...options,
  });
  return response.data;
};

export const getOrderItemStockLink = async (
  orderItemStockLinkId: string
): Promise<OrderItemStockLink> => {
  const response = await axiosClient.get(
    getOrderItemStockLinkURL(orderItemStockLinkId)
  );
  return response.data;
};

export const partialUpdateOrderItemStockLink = async (
  orderItemId: string,
  orderItemRequest: Partial<OrderItemStockLinkRequest>
): Promise<OrderItemStockLink> => {
  const response = await axiosClient.patch(
    getOrderItemStockLinkURL(orderItemId),
    orderItemRequest
  );
  return response.data;
};

export const listOrderItemStockLinkUsedStockHistory = async (
  stockId: string,
  options?: ListOptions
): Promise<PaginationResponse<OrderItemStockLink>> => {
  const { params, ...rest } = options ?? {};

  const response = await axiosClient.get(
    getOrderItemStockLinkUsedStockHistoryURL(),
    {
      params: {
        stock_id: stockId,
        ...params,
      },
      ...rest,
    }
  );
  return response.data;
};

export const previewOrdersCSV = async (formData: FormData) => {
  const response = await axiosClient.post<{
    preview: OrdersUploadPreview[];
    errors: Record<string, OrdersUploadPreviewCustomerError>;
  }>(apiURL(`${ORDER_URL}preview-csv/`), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const confirmOrdersCSV = async (data: { orders: unknown[] }) => {
  const response = await axiosClient.post(
    apiURL(`${ORDER_URL}confirm-csv/`),
    data
  );
  return response.data;
};

export const getZupplySKUMappings = async (options?: ListOptions) => {
  const response = await axiosClient.get<PaginationResponse<ZupplySKUMappings>>(
    ZUPPLY_SKU_MAPPINGS_URL,
    { ...options }
  );
  return response.data;
};
