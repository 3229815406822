import axiosClient from "../../axios";
import { bulkDeleteAPIURL } from "../paths";

export const bulkDelete = async ({
  context,
  ids,
  force = false,
}: {
  context: string;
  ids: (string | number)[];
  force?: boolean;
}): Promise<void> => {
  const response = await axiosClient.post(bulkDeleteAPIURL(context), {
    ids: ids,
    force: force,
  });
  return response.data;
};
