import { DATE_FORMAT, EASY_DATE_FORMAT } from "@/constants/formats";
import { logOnDev } from "@/services/axios/interceptors/response";
import axios from "axios";
import { type ClassValue, clsx } from "clsx";
import { format } from "date-fns";
import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  ) as React.ReactElement[];
}

export const stringToColour = function (str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;
  const saturation = 70;
  const lightness = 70;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

type ChipStyle = {
  backgroundColor: string;
};
export const stringToHSL = (() => {
  const cache: { [key: string]: ChipStyle } = {};

  return (str: string): ChipStyle => {
    if (cache[str]) {
      return cache[str];
    }

    let hash = 0;
    let s = 0;
    let l = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      s = hash << 5;
      l = (hash << 5) - hash;
    }

    const hue = hash % 360;
    const saturation = 60 + (s % 20);
    const lightness = 50 + (l % 20);

    const backgroundColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    const chipStyle = { backgroundColor };

    cache[str] = chipStyle;
    return chipStyle;
  };
})();

type NestedObject = Record<string, TODO>;

export function getElementByStringPath(
  obj: NestedObject,
  path: string
): unknown {
  const keys = path.split(".");
  let current = obj;

  for (const key of keys) {
    if (Object.hasOwn(current, key)) {
      current = current[key];
    } else {
      return undefined; // Key not found
    }
  }

  return current;
}

export function stringToDate(dateString: string): Date {
  return new Date(dateString);
}

export function stringToEasyDateString(dateString: string): string {
  return format(new Date(dateString), EASY_DATE_FORMAT);
}

export function dateToString(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const isNumbers = (str: string) => /^\d*$/.test(str);

export const _handleFormError = (form: UseFormReturn, error: unknown) => {
  // Not an axios error
  if (!axios.isAxiosError(error)) {
    form.setError("root", {
      message: "An error occured, please try again later.",
    });
    return;
  }
  if (!error.response) {
    form.setError("root", {
      message: "An error occured, please try again later.",
    });
    return;
  }
  const errorData = error.response.data;

  logOnDev("errorData:", errorData);

  if (Array.isArray(errorData)) {
    form.setError("root", { message: errorData.join("\n") });
  } else if (typeof errorData === "object" && "error" in errorData) {
    form.setError("root", { message: errorData.error });
  } else if (typeof errorData === "object") {
    for (const fieldKey in errorData) {
      logOnDev("setting error for", fieldKey);

      if (Object.hasOwn(errorData, fieldKey)) {
        const errorMessage = errorData[fieldKey];
        form.setError(fieldKey, {
          message: Array.isArray(errorMessage)
            ? errorMessage.join("\n")
            : errorMessage,
        });
      }
    }
  } else {
    form.setError("root", {
      message: "An error occured, please try again later.",
    });
  }
};

/**
 * Handles form errors by setting form error messages based on Axios error responses.
 * @param {UseFormReturn} form - The form instance from react-hook-form.
 * @param {unknown} error - The error object, potentially an AxiosError.
 */
export const handleFormError = (form: UseFormReturn<TODO>, error: unknown) => {
  // Non-Axios errors
  if (!axios.isAxiosError(error)) {
    form.setError("root", {
      message: "An unexpected error occurred. Please try again.",
    });
    return;
  }

  const { response } = error;

  // Network errors or no response from server
  if (!response) {
    form.setError("root", {
      message:
        "Network error. Please check your internet connection and try again.",
    });
    return;
  }
  const { data, status } = response;

  // Handle by status code if needed, e.g., unauthorized, forbidden, etc.
  if (status === 401) {
    form.setError("root", {
      message: "Unauthorized access. Please log in again.",
    });
    return;
  }

  if (Array.isArray(data)) {
    form.setError("root", { message: data.join("\n") });
  } else if (typeof data === "object" && "error" in data) {
    form.setError("root", { message: data.error });
  } else if (typeof data === "object") {
    for (const fieldKey in data) {
      logOnDev("setting error for", fieldKey, Object.hasOwn(data, fieldKey));

      const values = form.getValues();
      const fieldExistsInForm = fieldKey in values;

      if (fieldExistsInForm) {
        const errorMessage = data[fieldKey];
        form.setError(fieldKey, {
          message: Array.isArray(errorMessage)
            ? errorMessage.join("\n")
            : errorMessage,
        });
      } else {
        form.setError("root", {
          message: "An unexpected error occurred. Please try again.",
        });
      }
    }
  } else {
    form.setError("root", {
      message: "An unexpected error occurred. Please try again.",
    });
  }
};

export const cleanDateForBackend = (date: string | null) => {
  if (date === null) return date;
  return format(new Date(date), DATE_FORMAT);
};

export const extractTextFromNode = (node: ReactNode): string => {
  // If the node is a string, return it directly
  if (typeof node === "string") {
    return node;
  }

  // If the node is a React element with children, extract text from children
  if (React.isValidElement(node)) {
    // Use a more specific type annotation with a defined props structure
    const element = node as React.ReactElement<{ children?: ReactNode }>;

    if (element.props.children) {
      // Children can be a single node or an array of nodes
      const children = React.Children.toArray(element.props.children);
      return children.map(child => extractTextFromNode(child)).join("");
    }
  }

  // Return an empty string for other cases (e.g., null, undefined, boolean)
  return "";
};

// Function to get the dates for the current week
export const getWeekDates = () => {
  const weekDates = [];
  const date = new Date();
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday

  for (let i = 0; i < 7; i++) {
    const newDate = new Date(date.setDate(diff + i));
    weekDates.push(
      newDate.toLocaleDateString("en-US", {
        weekday: "long",
        day: "numeric",
        month: "short",
      })
    );
  }
  return weekDates;
};

export const groupBy = <T, K extends keyof unknown>(
  list: T[],
  getKey: (item: T) => K
) =>
  list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>
  );
