import { BASE_BADGE_CLASSNAME } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { usePusherContext } from "@/services/context/pusher/pusher-context";
import { RefreshCcwDot } from "lucide-react";

// TODO: Fix nested button
const NotSynced = () => {
  const { isLoading } = usePusherContext();

  const handleOnClick = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <span>Not synced</span>
      <button onClick={handleOnClick}>
        <RefreshCcwDot
          className={cn("mobile:h-4 mobile:w-4 h-3 w-3", {
            "direction-reverse animate-spin": isLoading,
          })}
        />
      </button>
    </div>
  );
};
const PusherStatus = () => {
  const { success } = usePusherContext();
  return (
    <>
      <div
        className={cn(
          "mobile:hidden block rounded-md border border-red-500 bg-red-500/20 px-1 text-xs font-bold text-red-500 opacity-50",
          {
            "border-success bg-success/20 text-success": success,
          }
        )}
      >
        {success ? "Live" : <NotSynced />}
      </div>
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className={cn(
                BASE_BADGE_CLASSNAME,
                "mobile:block hidden h-2 w-2 bg-red-500 p-0",
                {
                  "bg-success": success,
                }
              )}
            />
          </TooltipTrigger>
          <TooltipContent
            side="right"
            className={cn(
              "border border-red-500 bg-red-500/20 font-bold text-red-500",
              {
                "bg-success/20 border-green-500 text-green-500": success,
              }
            )}
          >
            {success ? "Live" : <NotSynced />}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
};

export default PusherStatus;
