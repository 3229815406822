import { ACCESS_TOKEN_NAME } from "../api/constants";
import { FINAL_BASE_URL } from "../api/paths";
import onErrorResponse from "./interceptors/error";
import onRequest from "./interceptors/request";
import onResponse from "./interceptors/response";
import axios from "axios";

const axiosClient = axios.create({
  baseURL: FINAL_BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: localStorage.getItem(ACCESS_TOKEN_NAME)
      ? "JWT " + localStorage.getItem(ACCESS_TOKEN_NAME)
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
  // withCredentials: true,
  // xsrfHeaderName: "X-CSRFTOKEN",
  // xsrfCookieName: "csrftoken",
});

axiosClient.interceptors.request.use(onRequest, onErrorResponse);
axiosClient.interceptors.response.use(onResponse, onErrorResponse);

export default axiosClient;
