import axiosClient from "../../axios";
import { bulkDelete } from "../base/actions";
import { apiURL } from "../paths";
import { ListOptions, ListRequest } from "../types/list";
import { ROLE_URL, getRoleURL } from "./paths";
import { Role, RoleRequest } from "@/types/user/role";

export const createRole = async (roleRequest: RoleRequest): Promise<Role> => {
  const response = await axiosClient.post(apiURL(ROLE_URL), roleRequest);
  return response.data;
};

export const removeRole = async (roleId: string): Promise<Role> => {
  const response = await axiosClient.delete(getRoleURL(roleId));
  return response.data;
};

export const bulkDeleteRole = async (
  params: Omit<Parameters<typeof bulkDelete>[0], "context">
): Promise<void> => {
  return bulkDelete({ context: ROLE_URL, ...params });
};

export const listRoles: ListRequest<never, Role> = async (
  options?: ListOptions
) => {
  const response = await axiosClient.get(apiURL(ROLE_URL), { ...options });
  return response.data;
};

export const getRole = async (roleId: string): Promise<Role> => {
  const response = await axiosClient.get(getRoleURL(roleId));
  return response.data;
};

export const updateRole = async (
  roleId: string,
  roleRequest: Partial<RoleRequest>
): Promise<Role> => {
  const response = await axiosClient.put(getRoleURL(roleId), roleRequest);
  return response.data;
};
