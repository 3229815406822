import { dashboardNavigationShortcutConfig } from "@/services/shortcuts/navigation";
import { SidebarNavigationSectionType } from "@/types/navigation";
import { LayoutDashboard } from "lucide-react";

const dashboard: SidebarNavigationSectionType = {
  id: "section-dashboard",
  title: "Dashboard",
  href: "",
  disableTitle: true,
  type: "section",
  items: [
    {
      id: "",
      title: "Dashboard",
      type: "item",
      href: "/",
      icon: LayoutDashboard,
      disableBreadcrumbs: true,
      shortcutConfig: dashboardNavigationShortcutConfig,
    },
  ],
};

export default dashboard;
