import { current } from "./current";
import dashboard from "./dashboard";
import settings from "./settings";
import tables from "./tables";
import { SidebarNavigationSectionType } from "@/types/navigation";

const sidebarMenuItems: SidebarNavigationSectionType[] = [
  dashboard,
  current,
  tables,
  settings,
];
export default sidebarMenuItems;
