import { logOnDev } from "@/services/axios/interceptors/response";
import { useAuthenticationAuthenticatedContext } from "@/services/context/authentication/authentication-context";
import { pusherOrganisationChannel } from "@/services/pusher/channels";
import { organisationChannelCreateFn } from "@/services/pusher/event-functions/create/organisation-channel-create-fn";
import { organisationChannelDeleteFn } from "@/services/pusher/event-functions/delete/organisation-channel-delete-fn";
import { clientOrganisationChannelUpdateFn } from "@/services/pusher/event-functions/update/client-organisation-channel-update-fn";
import { organisationChannelUpdateAllFn } from "@/services/pusher/event-functions/update/organisation-channel-update-all-fn";
import { organisationChannelUpdateFn } from "@/services/pusher/event-functions/update/organisation-channel-update-fn";
import {
  CLIENT_UPDATE_EVENT,
  CREATE_EVENT,
  DELETE_EVENT,
  UPDATE_ALL_EVENT,
  UPDATE_EVENT,
} from "@/services/pusher/events";
import { PusherAllEventData } from "@/services/pusher/types";
import { useQueryClient } from "@tanstack/react-query";
import Pusher from "pusher-js/types/src/core/pusher";
import { useCallback, useEffect, useState } from "react";

type TuseReactQuerySubscriptionProps = {
  pusher: Pusher;
};

type EventBinding = {
  [event: string]: (...props: any) => void | Promise<void>;
};

const EVENT_BINDINGS: EventBinding = {
  [UPDATE_EVENT]: organisationChannelUpdateFn,
  [UPDATE_ALL_EVENT]: organisationChannelUpdateAllFn,
  [CLIENT_UPDATE_EVENT]: clientOrganisationChannelUpdateFn,
  [CREATE_EVENT]: organisationChannelCreateFn,
  [DELETE_EVENT]: organisationChannelDeleteFn,
} as const;

export const useReactQuerySubscription = ({
  pusher,
}: TuseReactQuerySubscriptionProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const { user } = useAuthenticationAuthenticatedContext();

  const subscribe = useCallback(() => {
    setIsLoading(true);
    // Create organisation channel
    const organisationChannel = pusher.subscribe(
      pusherOrganisationChannel(user.organisation.code)
    );

    Object.keys(EVENT_BINDINGS).forEach(event => {
      organisationChannel.bind(event, (data: PusherAllEventData) =>
        EVENT_BINDINGS[event](queryClient, data)
      );
    });

    // Other Bindings
    organisationChannel.bind("pusher:subscription_succeeded", () => {
      setSuccess(true);
      logOnDev("[‼️][AUTHENTICATED PUSHER ORG CHANNEL]");
    });

    organisationChannel.bind("pusher:subscription_error", () => {
      setSuccess(false);
      logOnDev("[‼️][FAILED TO AUTHENTICATE PUSHER ORG CHANNEL]");
    });

    pusher.connection.bind("connected", () => {
      setSuccess(true);
      logOnDev("[‼️][PUSHER CONNECTION SUCCESS]");
    });

    pusher.connection.bind("error", () => {
      setSuccess(false);
      logOnDev("[‼️][PUSHER CONNECTION ERROR]");
    });

    setIsLoading(false);

    return () => {
      organisationChannel.unbind_all();
      organisationChannel.unsubscribe();
    };
  }, [pusher]);

  useEffect(() => {
    logOnDev("PUSHER - UNBIND & UNSUBSCRIBE");
    const unsubscribe = subscribe();
    return unsubscribe; // Cleanup subscription on unmount
  }, [pusher]);

  return { success, isLoading } as const;
};
