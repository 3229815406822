import { SidebarNavigationItem } from "./item";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  SidebarMenuAction,
  SidebarMenuItem,
  SidebarMenuSub,
} from "@/components/ui/sidebar";
import { SidebarNavigationGroupType } from "@/types/navigation";
import { ChevronRight } from "lucide-react";
import React from "react";

export const SidebarNavigationGroup = ({
  group,
  sectionHref,
}: {
  group: SidebarNavigationGroupType;
  sectionHref: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const callbackRef = React.useCallback((node: HTMLLIElement | null) => {
    if (!node) {
      return;
    }

    const hasActiveChild = node.querySelector("[aria-current='page']");

    if (hasActiveChild) {
      setIsOpen(true);
    }
  }, []);

  if (group.type !== "group") return null;

  return (
    <Collapsible key={group.id} asChild open={isOpen} onOpenChange={setIsOpen}>
      <SidebarMenuItem ref={callbackRef}>
        <SidebarNavigationItem
          item={group}
          groupHref={sectionHref}
          onClick={() => setIsOpen(prev => !prev)}
        />
        <CollapsibleTrigger asChild>
          <SidebarMenuAction
            className="data-[state=open]:rotate-90"
            onClick={() => setIsOpen(prev => !prev)}
          >
            <ChevronRight />
            <span className="sr-only">Toggle</span>
          </SidebarMenuAction>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {group.items.map(item => (
              <SidebarNavigationItem
                key={item.id}
                item={item}
                groupHref={sectionHref}
              />
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
};
