import "../app/globals.css";
import App from "./App.tsx";
import { RefreshTokenError } from "./services/axios/interceptors/error.ts";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { isAxiosError } from "axios";
import Pusher from "pusher-js";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Pusher.logToConsole = process.env.NODE_ENV !== "production";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: `https://${import.meta.env.VITE_SENTRY_PUBLIC_KEY}@${
      import.meta.env.VITE_SENTRY_SECRET_KEY
    }.ingest.sentry.io/${import.meta.env.VITE_SENTRY_PROJECT_ID}`,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: ["localhost", "api.necta.app"],
    environment: process.env.NODE_ENV ?? "development",

    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // TODO: Ultimately change to unlimited stale time w/ integration of push
      staleTime: 1000 * 60 * 60, // 60 minutes
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (error instanceof RefreshTokenError) {
          return false;
        }

        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          return false;
        }

        return true;
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
