import Loader from "../components/extended/loadable/loader";
import MainLayout from "../components/layout/main-layout";
import { ROUTES } from "../constants/urls";
import Loading from "../pages/loading";
import { logOnDev } from "../services/axios/interceptors/response";
import { useAuthenticationContext } from "../services/context/authentication/authentication-context";
import authenticationRoutes from "./authentication";
import routes from "./dashboard";
import { AppRouteType } from "./dashboard/types";
import shareRoutes from "./other/share";
import { useCallback, useMemo } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
  LoaderFunctionArgs,
  redirect,
} from "react-router-dom";

function Routing() {
  const { isLoggedIn, isLoading, passesClearanceLevel } =
    useAuthenticationContext();

  //   const protectedLoader = useCallback(
  //     ({ request }: LoaderFunctionArgs) => {
  //       logOnDev(`[protectedLoader] [isLoggedIn: ${isLoggedIn}]`);
  //       if (isLoggedIn) return null;

  //       // if the user is not logged in, add the from param
  //       const params = new URLSearchParams();
  //       params.set("from", new URL(request.url).pathname);
  //       logOnDev("\t-", request.url);

  //       if (isLoggedIn) return null;
  //       return redirect("/" + ROUTES.LOGIN + "?" + params.toString());
  //     },
  //     [isLoggedIn],
  //   );

  const loginLoader = useCallback(
    async ({ request }: LoaderFunctionArgs) => {
      logOnDev(`[loginLoader] [isLoggedIn: ${isLoggedIn}]`);
      if (!isLoggedIn) return null;

      const params = new URLSearchParams();
      const url = new URL(request.url);
      const from = url.searchParams.get("from") ?? "";
      logOnDev("\t- params:", params, request.url);
      logOnDev("\t- redirecting to:", "/" + from);

      // if (!isLoggedIn) return null;
      return redirect(from);
    },
    [isLoggedIn]
  );

  const generateRoutes = (routes: AppRouteType[]): React.ReactNode[] => {
    return routes
      .filter(({ clearanceLevel }) => passesClearanceLevel(clearanceLevel))
      .map((route, index) => {
        const key = `${route.pathname ?? index}-${route.name instanceof String ? route.name : route.type}`;
        const props = {
          path: route.pathname,
          handle: {
            name: route.name,
            type: route.type,
            clearanceLevel: route.clearanceLevel,
          },
        };

        if (!route.children) {
          return (
            <Route
              key={key}
              {...props}
              element={route.element}
              index={route.index}
            />
          );
        }

        const children = generateRoutes(route.children);

        if (route.type === "parent") {
          return (
            <Route key={key} {...props} element={route.element}>
              {children}
            </Route>
          );
        } else {
          return (
            <Route key={key} {...props}>
              {route.element && <Route index element={route.element} />}
              {children}
            </Route>
          );
        }
      });
  };

  const _routes = useMemo(() => {
    return createRoutesFromElements(
      <>
        {isLoggedIn && !isLoading && (
          <Route path="/" element={<MainLayout />}>
            {generateRoutes(routes)}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        )}

        {!isLoggedIn && !isLoading && (
          <>
            {authenticationRoutes({ loginLoader })}
            {shareRoutes()}
          </>
        )}

        {isLoading ? (
          <Route path="*" element={<Loading />} loader={loginLoader} />
        ) : (
          <Route
            path="*"
            element={<Navigate replace to={"/" + ROUTES.LOGIN} />}
            loader={loginLoader}
          />
        )}
      </>
    );
  }, [isLoading, isLoggedIn]);

  if (isLoading) return <Loader />;

  const router = createBrowserRouter(_routes);

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
}

export default Routing;
