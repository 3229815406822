import { AppRouteType } from "./types";
import Loadable from "@/components/extended/loadable/loadable";
import { ClearanceLevel } from "@/constants/clearance-levels";
import { RoleLevelBoundary } from "@/services/api/constants";
import {
  Building,
  CircleUser,
  KeyRound,
  Keyboard,
  type LucideIcon,
} from "lucide-react";
import { ReactNode, lazy } from "react";
import { Navigate } from "react-router-dom";

export type NavigationItem = {
  title: string;
  href: string;
  Icon: LucideIcon;
  clearanceLevel: undefined | ClearanceLevel | RoleLevelBoundary;
  className?: string;
  children?: NavigationItem[];
  element?: ReactNode;
};

export const settingsNavigationItems: NavigationItem[] = [
  {
    title: "Account",
    href: "account",
    Icon: CircleUser,
    clearanceLevel: undefined,
  },
  {
    title: "Password & Security",
    href: "security",
    Icon: KeyRound,
    clearanceLevel: undefined,
  },
  {
    title: "Organisation",
    href: "organisation",
    Icon: Building,
    clearanceLevel: ClearanceLevel.ORGANISATION,
  },
  {
    title: "Keyboard Shortcuts",
    href: "keyboard-shortcuts",
    Icon: Keyboard,
    clearanceLevel: undefined,
  },
  // {
  //   title: "Support",
  //   href: "support",
  //   Icon: Headset,
  //   clearanceLevel: ClearanceLevel.ORGANISATION,
  // }, TODO
  // {
  //   title: "Advanced",
  //   href: "advanced",
  //   Icon: Building,
  //   clearanceLevel: ClearanceLevel.ORGANISATION,
  // }, TODO
] satisfies NavigationItem[];

type SidebarNavItem = Omit<
  (typeof settingsNavigationItems)[number],
  "children"
>; // Extracts the type of an array element
type HrefValues = SidebarNavItem["href"]; // Extracts the type of the 'href' property

const componentMapping: Record<HrefValues, ReactNode> = {
  account: Loadable(lazy(() => import("@/pages/settings/account"))),
  security: Loadable(lazy(() => import("@/pages/settings/security"))),
  organisation: Loadable(lazy(() => import("@/pages/settings/organisation"))),
  "keyboard-shortcuts": Loadable(
    lazy(() => import("@/pages/settings/keyboard-shortcuts"))
  ),
  // integrations: Loadable(lazy(() => import("@/pages/settings/integrations"))),
  // support: Loadable(lazy(() => import("@/pages/help"))),
};

// Mapping sidebarNavItems to AppRouteType children
const childrenRoutes: AppRouteType[] = settingsNavigationItems.map(
  ({ title, href, clearanceLevel, element, children }): AppRouteType =>
    children
      ? {
          name: title,
          pathname: href,
          type: "parent" as const,
          element: element ?? componentMapping[href] ?? null,
          clearanceLevel,
          children: children.map(child => ({
            name: child.title,
            pathname: child.href,
            type: "item" as const,
            element: child.element ?? null,
            clearanceLevel: child.clearanceLevel,
          })),
        }
      : {
          name: title,
          pathname: href,
          type: "item" as const,
          element: element ?? componentMapping[href] ?? null,
          clearanceLevel,
        }
);

export const settingsRoutes: AppRouteType[] = [
  {
    name: "Settings",
    pathname: "settings",
    element: Loadable(lazy(() => import("@/pages/settings"))),
    type: "parent",
    children: [
      {
        name: undefined,
        type: "item",
        index: true,
        element: <Navigate to="account" replace />,
      },
      ...childrenRoutes,
      // Add other children routes here
    ],
  },
  {
    name: "Integrations",
    pathname: "integrations",
    type: "item",
    element: Loadable(lazy(() => import("@/pages/settings/integrations"))),
    clearanceLevel: ClearanceLevel.ORGANISATION,
    children: [
      {
        name: "Integration",
        pathname: ":integrationId",
        type: "item",
        element: Loadable(
          lazy(() => import("@/pages/settings/integrations/integration"))
        ),
        clearanceLevel: ClearanceLevel.ORGANISATION,
      },
    ],
  },
];
