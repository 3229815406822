import { KeyboardShortcut } from "@/components/extended/KeyboardShortcut";
import {
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import { useAuthenticationAuthenticatedContext } from "@/services/context/authentication/authentication-context";
import { SidebarNavigationElementType } from "@/types/navigation";
import React, { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const ItemLabel = ({
  label,
  className,
}: {
  label: React.ReactNode;
  className?: string;
}) => {
  if (!label) return null;

  if (typeof label === "string") {
    return (
      <span
        className={cn(
          "ml-2 rounded-md px-1.5 text-xs leading-none no-underline group-hover:no-underline",
          className
        )}
      >
        {label}
      </span>
    );
  }

  return label;
};

const ItemLabelSection = ({ item }: { item: SidebarNavigationElementType }) => {
  const navigate = useNavigate();
  const { showShortcuts } = useSidebar();

  const { shortcutConfig } = item;

  return (
    <SidebarMenuBadge className="flex items-center gap-2">
      <div
        className={cn({
          hidden: showShortcuts,
        })}
      >
        <ItemLabel
          label={item.label}
          className={cn({
            hidden: showShortcuts,
          })}
        />
      </div>

      {!!shortcutConfig && (
        <KeyboardShortcut
          shortcutConfig={shortcutConfig}
          shortcutAction={() => navigate(item.href)}
          className={cn({
            hidden: !showShortcuts,
          })}
        />
      )}
    </SidebarMenuBadge>
  );
};

interface SidebarItemProps {
  item: SidebarNavigationElementType;
  isActive: boolean;
}
const SidebarItem = React.memo(({ item, isActive }: SidebarItemProps) => {
  const Icon = item.icon;
  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        {!!Icon && (
          <Icon
            size={"2em"}
            className={cn(
              "group-[.active]:text-primary h-8 w-8 origin-center p-1 pr-2 pl-2 delay-75 group-[.active]:scale-105",
              {
                "sm:group-hover:scale-105": !isActive,
              }
            )}
          />
        )}
        <span className={cn("group-[.active]:text-primary block delay-75")}>
          {item.title}
        </span>
      </div>
      <ItemLabelSection item={item} />
    </div>
  );
});

SidebarItem.displayName = "SidebarItem";

export const SidebarNavigationItem = React.memo(
  ({
    item,
    groupHref,
    onClick,
  }: {
    item: SidebarNavigationElementType;
    groupHref: string;
    onClick?: () => void;
  }) => {
    const { setOpenMobile } = useSidebar();
    const { passesClearanceLevel } = useAuthenticationAuthenticatedContext();

    const handleNavLinkClick = useCallback(() => {
      setOpenMobile(false);
      onClick?.();
    }, [setOpenMobile, onClick]);

    const fullHref = groupHref + item.href;

    if (!passesClearanceLevel(item.clearanceLevel)) {
      return null;
    }

    const props =
      item.type === "item"
        ? {
            target: item.external ? "_blank" : "",
            rel: item.external ? "noreferrer" : "",
          }
        : {};

    return (
      <SidebarMenuItem>
        <NavLink
          key={item.id}
          to={fullHref}
          onClick={handleNavLinkClick}
          className={cn("group h-fit")}
          {...props}
        >
          <SidebarMenuButton
            tooltip={item.title}
            className="group-[.active]:text-primary h-fit"
          >
            {item.icon && <item.icon size={"2em"} />}
            {item.title}
            <ItemLabelSection item={item} />
          </SidebarMenuButton>
        </NavLink>
      </SidebarMenuItem>
    );
  }
);

SidebarNavigationItem.displayName = "SidebarNavigationItem";
