import { Input } from "../ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { cn } from "@/lib/utils";

interface NectaProps {
  className?: string;
}
const Necta = ({ className }: NectaProps) => {
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className={cn("font-logo", className)}>necta</span>
        </TooltipTrigger>
        <TooltipContent className="rounded-lg p-0">
          <Input
            value={`v${import.meta.env.VITE_APP_VERSION}`}
            readOnly
            copyable
            className="text-muted-foreground text-center font-mono text-xs"
          />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default Necta;
