import { AppRouteType } from "./types";
import Loadable from "@/components/extended/loadable/loadable";
import { ClearanceLevel } from "@/constants/clearance-levels";
import { TableURL } from "@/constants/urls";
import { lazy } from "react";

const baseOrderRoutes: Omit<AppRouteType, "element"> = {
  name: "Orders",
  pathname: TableURL.ORDERS,
  type: "item",
  clearanceLevel: ClearanceLevel.ORDERS,
  children: [
    {
      name: "Edit Order",
      pathname: ":orderId",
      type: "item",
      element: Loadable(lazy(() => import("@/pages/edit/order"))),
      children: [
        {
          name: "Edit Order Item",
          pathname: "order-item/:orderItemId",
          type: "item",
          element: Loadable(
            lazy(() => import("@/pages/edit/order/order-item"))
          ),
          children: [
            {
              name: "Edit Stock Link",
              pathname: "stock-link/:orderItemStockLinkId",
              type: "item",
              element: Loadable(
                lazy(
                  () =>
                    import(
                      "@/pages/edit/order/order-item/order-item-stock-link"
                    )
                )
              ),
            },
          ],
        },
      ],
    },
  ],
};

export const defaultOrderRoutes = {
  ...baseOrderRoutes,
  element: Loadable(lazy(() => import("@/pages/tables/orders"))),
} as AppRouteType;

export const currentOrderRoutes = {
  ...baseOrderRoutes,
  element: Loadable(lazy(() => import("@/pages/tables/current/orders"))),
} as AppRouteType;
