import { ThemeModeToggle } from "./theme-mode-toggle";
import { KeyboardShortcut } from "@/components/extended/KeyboardShortcut";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { Switch } from "@/components/ui/switch";
import { ClearanceLevel } from "@/constants/clearance-levels";
import { ROUTES } from "@/constants/urls";
import { useIsMobile } from "@/hooks/use-mobile";
import { ShortcutConfig } from "@/hooks/useKeyboardShortcut";
import { stringToHSL } from "@/lib/utils";
import { useRoleQuery } from "@/pages/edit/role/queries";
import { useAuthenticationAuthenticatedContext } from "@/services/context/authentication/authentication-context";
import {
  aboutShortcutConfig,
  helpShortcutConfig,
  settingsShortcutConfig,
} from "@/services/shortcuts/pages";
import { logoutShortcutConfig } from "@/services/shortcuts/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const userNavigationShortcutConfig: ShortcutConfig = {
  code: "KeyM",
  ctrlKey: true,
  shiftKey: true,
};

export function UserNavigation() {
  const { user, handleLogout, passesClearanceLevel, teamsMode, setTeamsMode } =
    useAuthenticationAuthenticatedContext();
  const navigate = useNavigate();
  const { data: role, isLoading: roleIsLoading } = useRoleQuery(user.role);
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleLogoutClick = async () => {
    await handleLogout();
  };

  const handleSettingsClick = () => {
    navigate("/" + ROUTES.SETTINGS);
  };

  const handleHelpClick = () => {
    navigate("/" + ROUTES.HELP);
  };

  const handleAboutClick = () => {
    navigate("/" + ROUTES.ABOUT);
  };

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              onClick={() => setOpen(true)}
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar>
                <AvatarFallback
                  className="text-background"
                  style={{
                    backgroundColor: stringToHSL(user.email).backgroundColor,
                  }}
                >
                  {user.first_name.charAt(0)}
                  {user.last_name.charAt(0)}
                </AvatarFallback>
              </Avatar>

              <div className="flex flex-1 flex-row items-center justify-between gap-1">
                <div className="grid truncate text-left text-xs leading-tight">
                  <span className="truncate font-semibold">
                    {user.first_name} {user.last_name}
                  </span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
                <KeyboardShortcut
                  shortcutAction={() => setOpen(prev => !prev)}
                  shortcutConfig={userNavigationShortcutConfig}
                />
              </div>
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-(--radix-dropdown-menu-trigger-width) min-w-56 rounded-lg"
            align="end"
            side={isMobile ? "bottom" : "right"}
            forceMount
          >
            <DropdownMenuLabel className="font-normal">
              <div className="flex flex-col space-y-1">
                <div className="flex h-full items-center justify-between">
                  <span className="text-sm leading-none font-medium">
                    {user.first_name} {user.last_name}
                  </span>
                  {roleIsLoading || !role ? (
                    <Badge variant="skeleton" className="h-2 w-2 p-0" />
                  ) : (
                    <Badge
                      variant="hash"
                      hash={role.name}
                      className="h-2 w-2 p-0"
                    ></Badge>
                  )}
                </div>
                <p className="text-muted-foreground text-xs leading-none">
                  {user.email}
                </p>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuLabel className="flex justify-between font-normal">
                Teams view
                <Switch checked={teamsMode} onCheckedChange={setTeamsMode} />
              </DropdownMenuLabel>
              <DropdownMenuItem onClick={handleSettingsClick}>
                Settings
                <DropdownMenuShortcut
                  shortcutAction={handleSettingsClick}
                  shortcutConfig={settingsShortcutConfig}
                />
              </DropdownMenuItem>
            </DropdownMenuGroup>

            <DropdownMenuSeparator />
            {passesClearanceLevel(ClearanceLevel.HELP) && (
              <DropdownMenuItem onClick={handleHelpClick}>
                Help
                <DropdownMenuShortcut
                  shortcutAction={handleHelpClick}
                  shortcutConfig={helpShortcutConfig}
                />
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={handleAboutClick}>
              About
              <DropdownMenuShortcut
                shortcutAction={handleAboutClick}
                shortcutConfig={aboutShortcutConfig}
              />
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <ThemeModeToggle />
            <DropdownMenuSeparator />

            <DropdownMenuItem onClick={handleLogoutClick}>
              Log out
              <DropdownMenuShortcut
                shortcutAction={handleLogoutClick}
                shortcutConfig={logoutShortcutConfig}
              />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
