import { cn } from "@/lib/utils";

interface IconProps extends React.HTMLAttributes<SVGElement> {
  clickable?: boolean;
}

const Logo = ({ clickable = false, ...props }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn({ "cursor-pointer": clickable }, props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7713 0.685497C13.0564 -0.228499 10.9436 -0.228499 9.22872 0.685497L2.77128 4.12719C1.05641 5.04118 0 6.73032 0 8.55831V15.4417C0 17.2697 1.05641 18.9588 2.77128 19.8728L9.22872 23.3145C10.9436 24.2285 13.0564 24.2285 14.7713 23.3145L21.2287 19.8728C22.9436 18.9588 24 17.2697 24 15.4417V8.55831C24 6.73032 22.9436 5.04118 21.2287 4.12719L14.7713 0.685497ZM8.45276 8.32218C8.06256 8.43133 7.76696 8.53503 7.56595 8.63327V16.2468H10.7939V10.3361C10.8294 10.2815 10.9536 10.2269 11.1664 10.1723C11.3911 10.1069 11.6157 10.0741 11.8404 10.0741C12.0769 10.0741 12.2838 10.0959 12.4611 10.1396C12.6503 10.1833 12.804 10.2542 12.9223 10.3524C13.0523 10.4398 13.1469 10.5653 13.2061 10.729C13.277 10.8818 13.3125 11.0674 13.3125 11.2857V16.2468H16.5405V10.5653C16.5405 9.80122 16.3749 9.21178 16.0439 8.797C15.7128 8.3713 15.2516 8.07658 14.6604 7.91285C14.0692 7.7382 13.3834 7.65087 12.603 7.65087C12.201 7.65087 11.7517 7.68362 11.2551 7.74911C10.7585 7.81461 10.2619 7.89647 9.76524 7.99471C9.28045 8.09295 8.84296 8.20211 8.45276 8.32218Z"
        fill="#F7A30A"
      />
    </svg>
  );
};

export default Logo;
