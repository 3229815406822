import { cn } from "@/lib/utils";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    readOnly?: boolean;
  }
>(({ readOnly, className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer focus-visible:ring-ring focus-visible:ring-offset-background data-[state=checked]:bg-primary data-[state=unchecked]:bg-input inline-flex h-5 w-10 shrink-0 cursor-pointer items-center rounded-full border-4 border-transparent transition-colors focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:outline-hidden disabled:cursor-not-allowed disabled:opacity-50",
      { "cursor-default": readOnly },
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "bg-background pointer-events-none block h-3 w-3 rounded-full shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-[calc(2.5rem-8px-0.75rem)] data-[state=unchecked]:translate-x-0" // transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
      )}
      // translate-x-[calc(2.5rem-4px-1rem)] is [SwitchPrimitives.Root width] - [2 * border width] - [SwitchPrimitives.Thumb width]
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
