// @ts-nocheck TODO: fix this
import { cn, getValidChildren } from "@/lib/utils";
import { ChevronRight } from "lucide-react";
import * as React from "react";

export interface BreadcrumbProps extends React.ComponentPropsWithoutRef<"nav"> {
  /* The visual separator between each breadcrumb item */
  separator?: React.ReactNode;
  /**
   * If `true`, adds a separator between each breadcrumb item.
   * @default true
   */
  addSeparator?: boolean;
}

export const Breadcrumb = React.forwardRef<HTMLElement, BreadcrumbProps>(
  (
    {
      children,
      className,
      separator = <ChevronRight className="size-4" />,
      addSeparator = true,
      ...props
    },
    forwardedRef
  ) => {
    const validChildren = getValidChildren(children);
    const clones = validChildren.map((child, index) => {
      return React.cloneElement(child, {
        addSeparator,
        separator,
        isLastChild: validChildren.length === index + 1,
      });
    });

    return (
      <nav
        className={cn("relative h-10 break-words", className)}
        aria-label="breadcrumb"
        {...props}
        ref={forwardedRef}
      >
        <ol className="flex items-center">{clones}</ol>
      </nav>
    );
  }
);
Breadcrumb.displayName = "Breadcrumb";

export interface BreadcrumbItemProps extends BreadcrumbProps {
  /**
   * If `true`, indicates that the breadcrumb item is active, adds
   * `aria-current=page` and renders a `span`
   */
  isCurrentPage?: boolean;
  isLastChild?: boolean;
}

export const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  BreadcrumbItemProps
>(
  (
    {
      children,
      className,
      isCurrentPage,
      isLastChild,
      separator,
      addSeparator,
      ...props
    },
    forwardedRef
  ) => {
    const validChildren = getValidChildren(children);
    const clones = validChildren.map(child => {
      if (child.type === BreadcrumbLink) {
        return React.cloneElement(child, { isCurrentPage });
      }

      if (child.type === BreadcrumbSeparator) {
        return React.cloneElement(child, {
          children: separator || child.props.children,
        });
      }

      return child;
    });

    return (
      <li
        className={cn("inline-flex min-w-fit items-center", className)}
        {...props}
        ref={forwardedRef}
      >
        {clones}
        {!isLastChild && addSeparator && (
          <BreadcrumbSeparator>{separator}</BreadcrumbSeparator>
        )}
      </li>
    );
  }
);
BreadcrumbItem.displayName = "BreadcrumbItem";

type BreadcrumbLinkProps<T extends React.ElementType> = {
  as: T;
  to?: string;
  isDisabled?: boolean;
} & React.ComponentPropsWithoutRef<T extends undefined ? "a" : T> &
  Pick<BreadcrumbItemProps, "isCurrentPage">;

const _BreadcrumbLink = <T extends React.ElementType>(
  {
    className,
    as: AsComp,
    isCurrentPage = false,
    isDisabled = false,
    ...props
  }: BreadcrumbLinkProps<T>,
  ref: React.ForwardedRef<React.ElementRef<T>>
) => {
  // const Component = AsComp;
  const Component: React.ElementType = AsComp || "a";
  const _isDisabled = isCurrentPage || isDisabled;

  return (
    <Component
      className={cn(
        "text-sm font-medium underline-offset-4",
        {
          "opacity-60": _isDisabled,
          "cursor-pointer hover:underline": !_isDisabled,
        },
        className
      )}
      aria-current={isCurrentPage ? "page" : undefined}
      {...props}
      ref={ref}
    />
  );
};

export const BreadcrumbLink = React.forwardRef(_BreadcrumbLink) as <
  T extends React.ElementType,
>(
  props: BreadcrumbLinkProps<T> & {
    ref?: React.ForwardedRef<React.ElementRef<T>>;
  }
) => ReturnType<typeof _BreadcrumbLink>;

export type BreadcrumbSeparatorProps = React.ComponentPropsWithoutRef<"span">;

export const BreadcrumbSeparator = React.forwardRef<
  HTMLSpanElement,
  BreadcrumbSeparatorProps
>(({ className, ...props }, forwardedRef) => {
  return (
    <span
      className={cn("mx-2 opacity-50", className)}
      role="presentation"
      {...props}
      ref={forwardedRef}
    />
  );
});
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";
