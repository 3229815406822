import { cn } from "@/lib/utils";
import { Copy, Check } from "lucide-react";
import * as React from "react";
import { useState } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  copyable?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, copyable, ...props }, ref) => {
    const [showCheckmark, setShowCheckmark] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleCopy = async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!props.value && !props.defaultValue) return;

      try {
        await navigator.clipboard.writeText(
          String(props.value || props.defaultValue)
        );
        setShowCheckmark(true);
        setTimeout(() => setShowCheckmark(false), 1000);
      } catch (error) {
        console.error("Failed to copy:", error);
      }
    };

    return (
      <div
        className="relative flex h-auto w-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <input
          type={type}
          className={cn(
            "border-input bg-background ring-offset-background flex h-full w-full rounded-md border px-3 py-2 text-sm",
            "file:border-0 file:bg-transparent file:text-sm file:font-medium",
            "placeholder:text-muted-foreground focus-visible:outline-none",
            "disabled:cursor-not-allowed disabled:opacity-50",
            "touch-action-manipulation tap-highlight-transparent",
            {
              "focus-visible:ring-ring focus-visible:ring-2 focus-visible:ring-offset-0":
                !props.readOnly,
              "pr-10": copyable,
            },
            className
          )}
          ref={ref}
          {...props}
        />
        {copyable && (
          <button
            onClick={handleCopy}
            className={cn(
              "absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer rounded-md p-1.5 transition-all duration-200",
              "hover:bg-muted",
              isHovered ? "opacity-100" : "opacity-0"
            )}
          >
            {showCheckmark ? (
              <Check className="text-muted-foreground h-4 w-4" />
            ) : (
              <Copy className="text-muted-foreground h-4 w-4" />
            )}
          </button>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
