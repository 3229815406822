import { useKeyboardShortcutsContext } from "@/features/keyboard-shortcuts";
import { useEffect, useState } from "react";

export type KeyboardCode =
  | "Backspace"
  | "Tab"
  | "Enter"
  | "ShiftLeft"
  | "ShiftRight"
  | "ControlLeft"
  | "ControlRight"
  | "AltLeft"
  | "AltRight"
  | "Pause"
  | "CapsLock"
  | "Escape"
  | "Space"
  | "PageUp"
  | "PageDown"
  | "End"
  | "Home"
  | "ArrowLeft"
  | "ArrowUp"
  | "ArrowRight"
  | "ArrowDown"
  | "PrintScreen"
  | "Insert"
  | "Delete"
  | "Digit0"
  | "Digit1"
  | "Digit2"
  | "Digit3"
  | "Digit4"
  | "Digit5"
  | "Digit6"
  | "Digit7"
  | "Digit8"
  | "Digit9"
  | "KeyA"
  | "KeyB"
  | "KeyC"
  | "KeyD"
  | "KeyE"
  | "KeyF"
  | "KeyG"
  | "KeyH"
  | "KeyI"
  | "KeyJ"
  | "KeyK"
  | "KeyL"
  | "KeyM"
  | "KeyN"
  | "KeyO"
  | "KeyP"
  | "KeyQ"
  | "KeyR"
  | "KeyS"
  | "KeyT"
  | "KeyU"
  | "KeyV"
  | "KeyW"
  | "KeyX"
  | "KeyY"
  | "KeyZ"
  | "MetaLeft"
  | "MetaRight"
  | "ContextMenu"
  | "Numpad0"
  | "Numpad1"
  | "Numpad2"
  | "Numpad3"
  | "Numpad4"
  | "Numpad5"
  | "Numpad6"
  | "Numpad7"
  | "Numpad8"
  | "Numpad9"
  | "NumpadMultiply"
  | "NumpadAdd"
  | "NumpadSubtract"
  | "NumpadDecimal"
  | "NumpadDivide"
  | "F1"
  | "F2"
  | "F3"
  | "F4"
  | "F5"
  | "F6"
  | "F7"
  | "F8"
  | "F9"
  | "F10"
  | "F11"
  | "F12"
  | "NumLock"
  | "ScrollLock"
  | "Semicolon"
  | "Equal"
  | "Comma"
  | "Minus"
  | "Period"
  | "Slash"
  | "Backquote"
  | "BracketLeft"
  | "BracketRight"
  | "Backslash"
  | "Quote";

type OptionalConfig = Pick<KeyboardEvent, "altKey" | "ctrlKey" | "shiftKey">;

export interface ShortcutConfig extends Partial<OptionalConfig> {
  code: KeyboardCode | KeyboardCode[];
  shortcutTarget?: HTMLElement | null;
  metaKey?: boolean;
}

export type ShortcutAction = () => void;

type UseKeyboardShortcutOptions = {
  onKeyDown?: ShortcutAction;
  onKeyUp?: ShortcutAction;
  config?: ShortcutConfig;
  disabled?: boolean;
  triggerOnKeyDown?: boolean;
};

const generateId = () => {
  if (import.meta.env.DEV) {
    return Math.random().toString(36).substring(2, 15);
  }
  return crypto.randomUUID();
};

export default function useKeyboardShortcut({
  onKeyDown,
  onKeyUp,
  config,
  disabled,
  triggerOnKeyDown,
}: UseKeyboardShortcutOptions) {
  const { registerShortcut } = useKeyboardShortcutsContext();
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    if (!onKeyUp || !config || disabled) return;

    const unregister = registerShortcut({
      id: generateId(),
      config,
      setIsPressed,
      onKeyDown,
      onKeyUp,
      disabled,
      triggerOnKeyDown,
    });

    return unregister;
  }, [
    config,
    disabled,
    onKeyDown,
    onKeyUp,
    registerShortcut,
    setIsPressed,
    triggerOnKeyDown,
  ]);

  return isPressed;
}
