import { Button } from "@/components/ui/button";
import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "@/components/ui/dropdown-menu";
import { useIsMobile } from "@/hooks/use-mobile";
import { cn } from "@/lib/utils";
import { useTheme } from "@/services/context/theme/theme-context";
import { Moon, Sun } from "lucide-react";

export function ThemeModeToggle() {
  const { theme, setTheme } = useTheme();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="text-foreground flex w-full items-center justify-between px-2">
        <span>Colour mode</span>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="icon"
            onClick={() => setTheme("light")}
            className={cn({
              "border-primary text-primary": theme === "light",
            })}
          >
            <Sun
              className={cn(
                "size-4 scale-100 transition-transform duration-300",
                {
                  "scale-125 rotate-45 stroke-3": theme === "light",
                }
              )}
            />
          </Button>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setTheme("dark")}
            className={cn({
              "border-primary text-primary": theme === "dark",
            })}
          >
            <Moon
              className={cn(
                "size-4 rotate-0 transition-transform duration-300",
                {
                  "scale-125 -rotate-12 stroke-3": theme === "dark",
                }
              )}
            />
          </Button>
        </div>
      </div>
    );
  }
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger className="w-full">
        <DropdownMenuLabel className="flex w-full cursor-pointer items-center justify-between p-0 font-normal">
          <span>Colour mode</span>
          <div>
            <Sun className="top-0 block h-4 w-4 dark:hidden" />
            <Moon className="top-0 hidden h-4 w-4 dark:block" />
          </div>
        </DropdownMenuLabel>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent className="mx-2">
          <DropdownMenuItem onClick={() => setTheme("light")}>
            Light
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("dark")}>
            Dark
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("system")}>
            System
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
}
