import { CurrentOrdersLabel } from "./CurrentOrdersLabel";
import { ClearanceLevel } from "@/constants/clearance-levels";
import {
  ordersNavigationShortcutConfig,
  stockNavigationShortcutConfig,
} from "@/services/shortcuts/navigation";
import { SidebarNavigationSectionType } from "@/types/navigation";
import { Boxes, CalendarFold, Package } from "lucide-react";

export const current: SidebarNavigationSectionType = {
  id: "current",
  title: "Current",
  type: "section",
  href: "/current",
  icon: CalendarFold,
  items: [
    {
      id: "orders",
      title: "Orders",
      label: <CurrentOrdersLabel />,
      type: "item",
      href: "/orders",
      icon: Package,
      clearanceLevel: ClearanceLevel.ORDERS,
      shortcutConfig: ordersNavigationShortcutConfig,
    },
    {
      id: "stock",
      title: "Stock",
      type: "item",
      href: "/stock",
      icon: Boxes,
      clearanceLevel: ClearanceLevel.STOCK,
      shortcutConfig: stockNavigationShortcutConfig,
    },
    // {
    //   id: "ai",
    //   title: <span className="font-logo">necta AI</span>,
    //   type: "item",
    //   href: "/ai",
    //   icon: BrainCircuit,
    //   clearanceLevel: ClearanceLevel.AI,
    // },
  ],
};
