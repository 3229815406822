import { KeyboardShortcutsContext } from "./KeyboardShortcutsContext";
import { useContext } from "react";

export const useKeyboardShortcutsContext = () => {
  const context = useContext(KeyboardShortcutsContext);

  if (!context) {
    throw new Error(
      "useKeyboardShortcutsContext must be used within a KeyboardShortcutsProvider"
    );
  }

  return context;
};
