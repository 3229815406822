import {
  BaseQueryData,
  PusherUpdateEventData,
  PusherUpdateDetailDataProp,
} from "../../types";
import assertField from "@/lib/utils/assert-field";
import { logOnDev } from "@/services/axios/interceptors/response";
import { PaginationResponse } from "@/types/table";
import { InfiniteData, QueryClient, QueryKey } from "@tanstack/react-query";

// Type guard to check if it's a PaginationResponse
function isPaginationResponse<T>(data: unknown): data is PaginationResponse<T> {
  if (typeof data !== "object" || !data) return false;
  return Object.hasOwn(data, "results");
}

// Type guard to check if it's an InfiniteQueryResponse
function isInfiniteQueryResponse<T>(data: unknown): data is InfiniteData<T> {
  if (typeof data !== "object" || !data) return false;
  return Object.hasOwn(data, "pages");
}

const isSearchInQueryKey = ({
  entity,
  queryKey,
}: {
  entity: readonly unknown[];
  queryKey: QueryKey;
}) => {
  if (queryKey.length < 3) return false;

  const numberOfEntities = entity.length;

  if (numberOfEntities + 1 >= queryKey.length) return false;

  const mismatchFound = entity.some(
    (entityItem, index) => queryKey[index] !== entityItem
  );

  if (mismatchFound) return false;

  if (queryKey[numberOfEntities] !== "list") return false;

  const filters = queryKey[numberOfEntities + 1];

  if (typeof filters !== "object" || !filters) return false;

  return "search" in filters;
};

export const baseOrganisationChannelUpdateFn = async (
  queryClient: QueryClient,
  data: PusherUpdateEventData
) => {
  logOnDev("[baseOrganisationChannelUpdateFn]", { data });
  const listQueryKey = [...data.entity, "list", ...(data.filters ?? [])].filter(
    Boolean
  );

  /**
   * If no id is provided, only invalidate the list
   */
  if (!assertField(data, "id")) {
    logOnDev("[baseOrganisationChannelUpdateFn] [invalidating LIST]", {
      listQueryKey,
    });
    await queryClient.invalidateQueries({
      queryKey: listQueryKey,
      exact: false,
    });
    return;
  }

  const detailQueryKey = [
    ...data.entity,
    "detail",
    data.id.toString(),
    ...(data.filters ? data.filters : []),
  ];

  /**
   * If an id is provided but no payload is provided, only invalidate the
   * detail.
   */
  if (!assertField(data, "payload")) {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: detailQueryKey,
      }),
      queryClient.removeQueries({
        predicate: query =>
          isSearchInQueryKey({ entity: data.entity, queryKey: query.queryKey }),
      }),
    ]);
    return;
  }

  await queryClient.removeQueries({
    predicate: query =>
      isSearchInQueryKey({ entity: data.entity, queryKey: query.queryKey }),
  });

  /**
   * If the id and payload are provided, then an update to an entity detail
   * occured. Therefore, set the query data to the new payload in the detail
   * cache, and all list cache.
   */
  // Set the query data for a specific item
  queryClient.setQueryData(detailQueryKey, data.payload);

  // Set the query data for a specific item in a list
  queryClient.setQueriesData<
    | PaginationResponse<BaseQueryData>
    | InfiniteData<PaginationResponse<BaseQueryData>>
    | Array<BaseQueryData>
    | undefined
  >({ queryKey: listQueryKey, exact: false, stale: false }, oldData => {
    if (!oldData) return oldData;
    if (isPaginationResponse<BaseQueryData>(oldData)) {
      return updateItemInPaginationResponseEarlyExit(oldData, data);
    } else if (isInfiniteQueryResponse(oldData)) {
      return updateItemInInfiniteDataEarlyExit(oldData, data);
    } else {
      return updateItemInPlainResponseEarlyExit(oldData, data);
    }
  });
};

function updateItemInInfiniteDataEarlyExit(
  infiniteData: InfiniteData<PaginationResponse<BaseQueryData>>,
  data: PusherUpdateDetailDataProp
): InfiniteData<PaginationResponse<BaseQueryData>> {
  // Clone the structure to avoid mutating the original data
  const updatedPages = JSON.parse(JSON.stringify(infiniteData.pages));

  outerLoop: for (const element of updatedPages) {
    for (let j = 0; j < element.results.length; j++) {
      if (element.results[j].id === data.id) {
        element.results[j] = data.payload;
        // Exit after updating the item
        break outerLoop;
        // TODO: uncomment when i implement a no early exit. e.g. when the item exists more than once in the list. will this ever happen?
      }
    }
  }

  return {
    ...infiniteData,
    pages: updatedPages,
  };
}

function updateItemInPaginationResponseEarlyExit(
  paginationResponse: PaginationResponse<BaseQueryData>,
  data: PusherUpdateDetailDataProp
): PaginationResponse<BaseQueryData> {
  // Clone the structure to avoid mutating the original data
  const updatedResults = JSON.parse(JSON.stringify(paginationResponse.results));

  for (let i = 0; i < updatedResults.length; i++) {
    if (updatedResults[i].id === data.id) {
      updatedResults[i] = data.payload;
      // Exit after updating the item
      break;
    }
  }

  return {
    ...paginationResponse,
    results: updatedResults,
  };
}

function updateItemInPlainResponseEarlyExit(
  plainResponse: Array<BaseQueryData>,
  data: PusherUpdateDetailDataProp
): Array<BaseQueryData> {
  // Clone the structure to avoid mutating the original data
  const updatedResults = JSON.parse(JSON.stringify(plainResponse));

  for (let i = 0; i < updatedResults.length; i++) {
    if (updatedResults[i].id === data.id) {
      updatedResults[i] = data.payload;
      // Exit after updating the item
      break;
    }
  }

  return updatedResults;
}
