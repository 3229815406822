import AuthenticationWrapper from "../authentication-wrapper";
import { LoginForm } from "./login-form";
import Necta from "@/components/extended/necta";
import { Link } from "@/components/ui/link";

export default function LoginPage() {
  const description = (
    <span className="">
      Don&apos;t have an account? <Link to="/register">Register today.</Link>
    </span>
  );
  return (
    <AuthenticationWrapper
      title={
        <span>
          Welcome back to <Necta />
        </span>
      }
      description={description}
      AuthenticationFormComponent={LoginForm}
    ></AuthenticationWrapper>
  );
}
