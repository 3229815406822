import useKeyboardShortcut, {
  ShortcutAction,
  ShortcutConfig,
} from "@/hooks/useKeyboardShortcut";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { convertShortcutToCharacters } from "@/lib/convertShortcutToCharacters";
import { cn } from "@/lib/utils";

export const KeyboardShortcut = ({
  shortcutAction,
  shortcutConfig,
  disabled = false,
  className,
}: {
  shortcutAction: ShortcutAction;
  shortcutConfig: ShortcutConfig;
  disabled?: boolean;
  className?: string;
}) => {
  const isGTSmall = useMediaQuery("sm");
  const isPressing = useKeyboardShortcut({
    onKeyUp: shortcutAction,
    config: shortcutConfig,
    disabled,
  });

  return (
    <div
      className={cn(
        "border-border bg-muted/20 relative z-50 h-6 w-fit items-center justify-center rounded-sm border px-[4px] text-xs leading-0 backdrop-blur-lg",
        {
          "opacity-60": disabled,
          "border-foreground/50 border-1": isPressing,
          hidden: !isGTSmall,
        },
        className
      )}
    >
      <div
        className={cn(
          "absolute top-1/2 left-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center font-mono",
          {
            "scale-105 font-semibold": isPressing,
          }
        )}
      >
        <kbd>{convertShortcutToCharacters(shortcutConfig)}</kbd>
      </div>
      {/* This is a hack to make sure there is no layout shift when the shortcut is pressed */}
      <kbd className="flex h-full w-full items-center justify-center font-mono opacity-0">
        {convertShortcutToCharacters(shortcutConfig)}
      </kbd>
    </div>
  );
};
