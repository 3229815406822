"use client";

import { tw } from "@/lib/utils/tw";
import { useTheme } from "@/services/context/theme/theme-context";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      mobileOffset={{
        bottom: "24px",
      }}
      toastOptions={{
        classNames: {
          toast: tw`toast group border-border bg-background text-foreground max-mobile:py-2 w-fit border shadow-lg`,
          description: "text-muted-foreground",
          actionButton: tw`actionButton`,
          error: tw`[&_.actionButton]:text-foreground bg-red-100/50! text-red-700! backdrop-blur-3xl! [&_.actionButton]:bg-red-700!`,
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
