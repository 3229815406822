import { AppRouteType } from "./types";
import Loadable from "@/components/extended/loadable/loadable";
import { TableURL } from "@/constants/urls";
import { lazy } from "react";

export const produceRoutes: AppRouteType = {
  name: "Produce",
  pathname: TableURL.PRODUCE,
  type: "item",
  element: Loadable(lazy(() => import("@/pages/tables/produce"))),
  children: [
    {
      name: "Edit Produce",
      pathname: ":produceId",
      type: "item",
      element: Loadable(lazy(() => import("@/pages/edit/produce"))),
      children: [
        {
          name: "Edit Produce Composite Component",
          pathname: "composite-component/:compositeProduceComponentId",
          type: "item",
          element: Loadable(
            lazy(
              () => import("@/pages/edit/produce/composite-produce-component")
            )
          ),
        },
        {
          name: "Edit Quantity Suffix",
          pathname: "quantity-suffix/:quantitySuffixId",
          type: "item",
          element: Loadable(
            lazy(() => import("@/pages/edit/produce/produce-quantity-suffix"))
          ),
        },
        {
          name: "Edit Variety",
          pathname: "variety/:varietyId",
          type: "item",
          element: Loadable(
            lazy(() => import("@/pages/edit/produce/produce-variety"))
          ),
        },
      ],
    },
  ],
};
