import { screenSizes } from "@/config/media";
import { useEffect, useState } from "react";

export const useMediaQuery = (screenSizeQuery: keyof typeof screenSizes) => {
  const query = `(min-width: ${screenSizes[screenSizeQuery]})`;

  const mediaQueryMatches = () => {
    return window.matchMedia(query).matches;
  };

  const [matches, setMatches] = useState<boolean>(mediaQueryMatches);

  useEffect(() => {
    const media = window.matchMedia(query);
    let timer: number;

    const listener = () => {
      clearTimeout(timer);
      timer = window.setTimeout(() => {
        setMatches(media.matches);
      }, 10);
    };

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
      clearTimeout(timer);
    };
  }, [matches, screenSizeQuery]);

  return matches;
};
