import { SidebarNavigationGroup } from "./group";
import { SidebarNavigationItem } from "./item";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
} from "@/components/ui/sidebar";
import { SidebarNavigationSectionType } from "@/types/navigation";

export const SidebarNavigationSection = ({
  section,
}: {
  section: SidebarNavigationSectionType;
}) => {
  const sectionItems = section.items.map(element => {
    if (element.type === "group") {
      return (
        <SidebarNavigationGroup
          group={element}
          key={element.id}
          sectionHref={section.href}
        />
      );
    } else if (element.type === "item") {
      return (
        <SidebarNavigationItem
          item={element}
          key={element.id}
          groupHref={section.href}
        />
      );
    }
  });

  return (
    <SidebarGroup key={section.id} className="w-full pb-4">
      <SidebarGroupLabel>{section.title}</SidebarGroupLabel>
      <SidebarMenu>{sectionItems}</SidebarMenu>
    </SidebarGroup>
  );
};
