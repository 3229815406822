import { PusherCreateEventData } from "../../types";
import { QueryClient } from "@tanstack/react-query";

export const organisationChannelCreateFn = (
  queryClient: QueryClient,
  data: PusherCreateEventData
) => {
  const listQueryKey = [
    ...data.entity,
    "list",
    ...(data.filters ? data.filters : []),
  ].filter(Boolean);

  queryClient.invalidateQueries({ queryKey: listQueryKey });
};
