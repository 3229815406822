import { RoleLevelBoundary } from "@/services/api/constants";

/**
 * Defines the clearance level for a given page category
 */
export enum ClearanceLevel {
  ABOUT = RoleLevelBoundary.WORKER,
  AI = RoleLevelBoundary.ORGANISATIONAL_ADMIN,
  CUSTOMERS = RoleLevelBoundary.OFFICE,
  HELP = RoleLevelBoundary.WORKER,
  ORDERS = RoleLevelBoundary.WORKER,
  ORGANISATION = RoleLevelBoundary.OFFICE,
  STOCK = RoleLevelBoundary.WORKER,
  TEAMS = RoleLevelBoundary.WORKER,
  USERS = RoleLevelBoundary.OFFICE,
}

// Define an extended version of ClearanceLevel for edits, overriding specific entries.
export enum EditClearanceLevel {
  TEAMS = RoleLevelBoundary.OFFICE,
}
