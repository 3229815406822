import Loadable from "@/components/extended/loadable/loadable";
import { lazy } from "react";
import { Route } from "react-router-dom";

const shareRoutes = () => {
  return (
    <Route
      path="/share/:hash"
      element={Loadable(lazy(() => import("@/pages/share")))}
    />
  );
};

export default shareRoutes;
