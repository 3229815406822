import axiosClient from "../../axios";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../constants";
import {
  apiURL,
  getActivateAccountURL,
  getResetPasswordURL,
  LOGOUT_URL,
  REGISTER_ADMIN_URL,
  REGISTER_USER_URL,
  SEND_EMAIL_VERIFICATION_URL,
  SEND_PASSWORD_RESET_URL,
  TOKEN_URL,
} from "../paths";
import {
  LoginRequest,
  LoginResponse,
  RegisterAdminRequest,
  RegisterResponse,
  RegisterUserRequest,
  SendNewAccountActivationEmailRequest,
} from "./types";
import { PasswordResetRequest } from "@/pages/authentication/reset-password/reset-password-form";

export const login = async (data: LoginRequest): Promise<LoginResponse> => {
  const response = await axiosClient.post(apiURL(TOKEN_URL), data);

  // Set tokens and headers
  localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access);
  localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refresh);
  axiosClient.defaults.headers["Authorization"] =
    "JWT " + localStorage.getItem(ACCESS_TOKEN_NAME);

  return response.data;
};

export const logout = async () => {
  const response = await axiosClient.post(apiURL(LOGOUT_URL), {
    refresh_token: localStorage.getItem(REFRESH_TOKEN_NAME),
  });

  // Local storage and headers
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  axiosClient.defaults.headers["Authorization"] = null;
  return response.data;
};

export const registerAdmin = async (
  registerAdminRequest: RegisterAdminRequest
): Promise<RegisterResponse> => {
  const response = await axiosClient.post(
    apiURL(REGISTER_ADMIN_URL),
    registerAdminRequest
  );

  return response.data;
};

export const registerUser = async (
  registerUserRequest: RegisterUserRequest
): Promise<RegisterResponse> => {
  const response = await axiosClient.post(
    apiURL(REGISTER_USER_URL),
    registerUserRequest
  );

  return response.data;
};

export const activateAccount = async (
  uid: string,
  token: string
): Promise<void> => {
  const response = await axiosClient.get(getActivateAccountURL(uid, token));

  return response.data;
};

export const sendNewAccountActivationEmail = async (
  userIdentifierRequest: SendNewAccountActivationEmailRequest
): Promise<void> => {
  const response = await axiosClient.post(
    SEND_EMAIL_VERIFICATION_URL,
    userIdentifierRequest
  );

  return response.data;
};

export const sendPasswordReset = async (email: string): Promise<void> => {
  const response = await axiosClient.post(SEND_PASSWORD_RESET_URL, { email });

  return response.data;
};

export const resetPassword = async (
  uid: string,
  token: string,
  passwordRequest: PasswordResetRequest
): Promise<void> => {
  const response = await axiosClient.post(
    getResetPasswordURL(uid, token),
    passwordRequest
  );

  return response.data;
};
