import { Button } from "@/components/ui/button";
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { FallbackProps } from "react-error-boundary";

export const ErrorFallback = (props: FallbackProps) => {
  const { resetErrorBoundary, error } = props;

  useEffect(() => {
    captureException(error);
  }, [error]);

  const handleTryAgain = () => {
    resetErrorBoundary();
  };

  const handleHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-10 p-4 text-center">
      <span className="text-4xl font-extrabold">🦺 🦺</span>
      <h1 className="text-4xl font-extrabold">
        Uh oh! It looks like an error has occured
      </h1>
      <div>
        Don&apos;t worry though; we will look into the issue shortly! In the
        meantime, you can{" "}
        <Button
          variant={"outline"}
          className="h-auto px-2 py-1"
          onClick={handleTryAgain}
        >
          try again
        </Button>{" "}
        or{" "}
        <Button
          variant={"outline"}
          className="h-auto px-2 py-1"
          onClick={handleHome}
        >
          go back
        </Button>{" "}
        to safety.
      </div>
    </div>
  );
};
