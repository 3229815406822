import { buttonVariants } from "./button";
import { cn } from "@/lib/utils";
import React from "react";
import { Link as LinkPrimative } from "react-router-dom";

export interface LinkProps
  extends React.ComponentPropsWithRef<typeof LinkPrimative> {}

const Link = ({ className, to, ...props }: LinkProps) => {
  return (
    <LinkPrimative
      to={to}
      className={cn(
        "text-primary underline-offset-4 hover:underline",
        className
      )}
      {...props}
    />
  );
};
Link.displayName = "Button";

export { Link, buttonVariants };
