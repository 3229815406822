import { useAuthenticationAuthenticatedContext } from "../authentication/authentication-context";
import { useReactQuerySubscription } from "@/hooks/useReactQuerySubscription";
import { getPusherAuthEndpoint } from "@/services/api/paths";
import axiosClient from "@/services/axios";
import Pusher from "pusher-js";
import { createContext, useContext, useMemo } from "react";

interface IPusherContext {
  pusher: Pusher;
  success: boolean;
  isLoading: boolean;
}

export const PusherContext = createContext<IPusherContext | undefined>(
  undefined
);

export const usePusherContext = (): IPusherContext => {
  const context = useContext(PusherContext);
  if (!context) {
    throw new Error("usePusherContext must be used within a PusherProvider");
  }
  return context;
};

interface PusherProviderProps {
  children: React.ReactNode;
}

export const PusherProvider = ({ children }: PusherProviderProps) => {
  const { user } = useAuthenticationAuthenticatedContext();
  const pusher = useMemo(
    () =>
      new Pusher(import.meta.env.VITE_PUSHER_PUBLIC_KEY, {
        cluster: import.meta.env.VITE_PUSHER_CLUSTER,
        authEndpoint: getPusherAuthEndpoint(),
        auth: {
          headers: {
            Authorization: axiosClient.defaults.headers.Authorization,
          },
        },
      }),
    [user.id, axiosClient.defaults.headers.Authorization]
  );

  const { success, isLoading } = useReactQuerySubscription({ pusher });

  const values = useMemo(
    () => ({
      pusher,
      success,
      isLoading,
    }),
    [pusher, success, isLoading]
  );

  return (
    <PusherContext.Provider value={values}>{children}</PusherContext.Provider>
  );
};
