import { BreadcrumbWithRoutingLibrary } from "../breadcrumb";
import MobileBottomNav from "../mobile-navigation";
import { AppSidebar } from "@/components/app-sidebar";
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import { Toaster } from "@/components/ui/sonner";
import { PusherProvider } from "@/services/context/pusher/pusher-context";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
  return (
    <PusherProvider>
      <SidebarProvider>
        <AppSidebar />
        <SidebarInset>
          <header className="_h-16 flex shrink-0 items-center gap-2 p-2">
            <BreadcrumbWithRoutingLibrary />
          </header>
          <div
            content="main"
            className="flex-1 overflow-y-auto p-2 pb-[calc(8rem+env(safe-area-inset-bottom))] md:pb-2"
          >
            <Outlet />
          </div>
          <MobileBottomNav />
        </SidebarInset>
      </SidebarProvider>
      <Toaster richColors position={"bottom-right"} />
    </PusherProvider>
  );
}
