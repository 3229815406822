import { PusherAllEventData } from "../../types";
import { baseOrganisationChannelUpdateFn } from "./base-organisation-channel-update-fn";
import { logOnDev } from "@/services/axios/interceptors/response";
import { QueryClient } from "@tanstack/react-query";

export const organisationChannelUpdateAllFn = (
  queryClient: QueryClient,
  data: PusherAllEventData
) => {
  logOnDev("[‼️][UPDATE ALL EVENT]", data);

  data.forEach(event => baseOrganisationChannelUpdateFn(queryClient, event));
};
