import Loader from "./loader";
import { Suspense, ReactNode, LazyExoticComponent } from "react";

const Loadable = (
  Component: LazyExoticComponent<() => React.ReactNode | null>
): ReactNode => {
  // Directly return the Suspense wrapped component
  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

export default Loadable;
