import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { SidebarTrigger, useSidebar } from "@/components/ui/sidebar";
import { ClearanceLevel } from "@/constants/clearance-levels";
import { cn } from "@/lib/utils";
import { RouteTypes } from "@/routing/dashboard/types";
import { RoleLevelBoundary } from "@/services/api/constants";
import { useAuthenticationAuthenticatedContext } from "@/services/context/authentication/authentication-context";
import { DoorClosed, PersonStanding } from "lucide-react";
import {
  Location,
  useLocation,
  useMatches,
  useNavigate,
} from "react-router-dom";
import { toast } from "sonner";

interface Params<T> {
  [key: string]: T | undefined;
}

export interface RouteHandle {
  name?: string | ((location: Location) => string);
  type: RouteTypes;
  clearanceLevel?: ClearanceLevel | RoleLevelBoundary;
}

export interface Match {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: RouteHandle;
}

const ActivateTeamsModeToast = () => {
  return (
    <div>
      Teams mode is now <span className="font-bold">active</span>. Stock will be
      filtered by the teams you are a member of.
    </div>
  );
};

const DeactivateTeamsModeToast = () => {
  return (
    <div>
      Teams mode is now <span className="font-bold">off</span>. All stock will
      be shown.
    </div>
  );
};

export function BreadcrumbWithRoutingLibrary() {
  const matches = useMatches() as Match[];
  const navigate = useNavigate();
  const location = useLocation();
  const { teamsMode, setTeamsMode } = useAuthenticationAuthenticatedContext();
  const { isMobile } = useSidebar();

  const breadcrumbs: Match[] =
    matches?.filter(match => Boolean(match.handle?.name)) || [];

  let title = "\u3164"; // invisible

  if (
    breadcrumbs.length > 0 &&
    breadcrumbs[breadcrumbs.length - 1].handle.name &&
    breadcrumbs[breadcrumbs.length - 1].handle.type !== "parent"
  ) {
    const lead = breadcrumbs[breadcrumbs.length - 1];
    if (lead.handle.name && lead.handle.type !== "parent") {
      title =
        typeof lead.handle.name === "function"
          ? lead.handle.name(location)
          : lead.handle.name;
    }
  }

  const homeDisabled = title === "Dashboard";
  let parentEncountered = false; // Flag to track if a "parent" type has been encountered

  const navigateHome = () => {
    navigate("/");
  };

  const toggleTeamsMode = () => {
    const newState = !teamsMode;
    setTeamsMode(newState);

    toast(newState ? <ActivateTeamsModeToast /> : <DeactivateTeamsModeToast />);
  };

  return (
    <div className="flex w-full items-start gap-2">
      <SidebarTrigger
        className={cn("size-5 min-h-5 min-w-5", {
          hidden: isMobile,
        })}
      />
      <Separator
        orientation="vertical"
        className={cn("h-6", {
          hidden: isMobile,
        })}
      />
      <div className="flex w-full flex-col gap-2">
        <div className="flex h-6 w-full flex-row items-center justify-center gap-2">
          <Breadcrumb className="h-6 w-full overflow-x-scroll whitespace-nowrap">
            <BreadcrumbItem>
              <BreadcrumbLink
                as={"button"}
                className="flex touch-none content-center items-center justify-center gap-1"
                onClick={navigateHome}
                isDisabled={homeDisabled}
                disabled={homeDisabled}
              >
                <DoorClosed className="size-4" />
                <span className="inline-block font-bold">Home</span>
              </BreadcrumbLink>
            </BreadcrumbItem>

            {breadcrumbs.map(({ pathname, handle }, key) => {
              if (pathname === "/") return;
              if (parentEncountered) return;
              if (handle.type === "parent") parentEncountered = true;

              const isCurrentPage = key === breadcrumbs.length - 1;
              const isDisabled = handle.type !== "item" || isCurrentPage;
              const navigateTo = () => {
                navigate(pathname);
              };

              const name =
                typeof handle.name === "function"
                  ? handle.name(location)
                  : handle.name;

              return (
                <BreadcrumbItem
                  key={pathname + key}
                  isCurrentPage={isCurrentPage}
                >
                  <BreadcrumbLink
                    as={"button"}
                    onClick={navigateTo}
                    isCurrentPage={key === breadcrumbs.length - 1}
                    isDisabled={isDisabled}
                    disabled={isDisabled}
                  >
                    {name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
          <div className="flex h-4 w-4 items-center justify-center">
            <Button
              variant="ghost"
              size="icon"
              className="size-6"
              onClick={toggleTeamsMode}
            >
              <PersonStanding
                key={"icon-teamsMode"}
                className={cn("text-primary h-4 w-4 opacity-80", {
                  "text-muted-foreground opacity-20": !teamsMode,
                })}
                aria-description="Teams mode is active."
              />
            </Button>
          </div>
        </div>
        <div className="text-4xl">{title}</div>
      </div>
    </div>
  );
}
