import { KeyboardShortcutsProvider } from "./features/keyboard-shortcuts";
import { ErrorFallback } from "./pages/error-boundary/fallback.tsx";
import Routing from "./routing";
import { AuthenticationProvider } from "./services/context/authentication/authentication-context.tsx";
import { ThemeProvider } from "./services/context/theme/theme-context.tsx";
import { NuqsAdapter } from "nuqs/adapters/react";
import { ErrorBoundary } from "react-error-boundary";

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthenticationProvider>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <KeyboardShortcutsProvider>
            <NuqsAdapter>
              <Routing />
            </NuqsAdapter>
          </KeyboardShortcutsProvider>
        </ThemeProvider>
      </AuthenticationProvider>
    </ErrorBoundary>
  );
}

export default App;
