import { ClearanceLevel } from "@/constants/clearance-levels";
import { useRoleQuery } from "@/pages/edit/role/queries";
import { login, logout } from "@/services/api/auth/actions";
import { LoginRequest, LoginResponse } from "@/services/api/auth/types";
import {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  RoleLevel,
  RoleLevelBoundary,
} from "@/services/api/constants";
import { LOGIN_URL } from "@/services/api/paths";
import { getUserInfo } from "@/services/api/user/actions";
import { UserSelf } from "@/types/user";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IAutheticationContext {
  isLoading: boolean;
  isLoggedIn: boolean;
  getUserRoleLevel: () => RoleLevel | null;

  handleLogin: UseMutateAsyncFunction<
    LoginResponse,
    Error,
    LoginRequest,
    unknown
  >;
  handleLogout: () => Promise<void>;
  passesClearanceLevel: (
    clearanceLevel?: RoleLevelBoundary | ClearanceLevel
  ) => boolean;
  teamsMode: boolean;
  setTeamsMode: (mode: boolean) => void;
}

interface IAutheticationContextAuthenticated extends IAutheticationContext {
  user: UserSelf;
}

interface IAutheticationContextNotAuthenticated extends IAutheticationContext {
  user: undefined;
}

export const AuthenticationContext = createContext<
  | IAutheticationContextNotAuthenticated
  | IAutheticationContextAuthenticated
  | undefined
>(undefined);

export const useAuthenticationContext = (): IAutheticationContext => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      "useAuthenticationContext must be used within a AuthenticationProvider"
    );
  }
  return context;
};

export const useAuthenticationAuthenticatedContext =
  (): IAutheticationContextAuthenticated => {
    const context = useContext(AuthenticationContext);
    if (!context) {
      throw new Error(
        "useAuthenticationAuthenticatedContext must be used within a AuthenticationProvider"
      );
    }

    if (!context.user) {
      throw new Error(
        "useAuthenticationAuthenticatedContext must be used within protected routes."
      );
    }
    return context;
  };

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

const DEFAULT_TEAMS_MODE = false;
const TEAMS_MODE_LS_KEY = "globals.necta.teams-mode";

export const AuthenticationProvider = ({
  children,
}: AuthenticationProviderProps) => {
  const [teamsMode, setTeamsMode] = useState<boolean>(DEFAULT_TEAMS_MODE);
  const queryClient = useQueryClient();

  useEffect(() => {
    setTeamsMode(localStorage.getItem(TEAMS_MODE_LS_KEY) === "true");
  }, []);

  const { data: user, isLoading } = useQuery<UserSelf>({
    queryKey: ["me"],
    queryFn: getUserInfo,
    enabled:
      !!localStorage.getItem(ACCESS_TOKEN_NAME) ||
      !!localStorage.getItem(REFRESH_TOKEN_NAME),
  });

  const { data: role, isLoading: roleIsLoading } = useRoleQuery(user?.role);

  const { mutateAsync: handleLogin } = useMutation({
    mutationFn: login,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });

  const isLoggedIn = useMemo(() => user !== undefined, [user]);

  const getUserRoleLevel = useCallback(() => {
    if (user && role) return role.level;
    return null;
  }, [user, role]);

  const handleLogout = async () => {
    await logout();
    queryClient.setQueriesData({ queryKey: ["me"] }, undefined);
    window.location.href = LOGIN_URL;
  };

  const handleSetTeamsMode = (mode: boolean) => {
    localStorage.setItem(TEAMS_MODE_LS_KEY, mode ? "true" : "false");
    setTeamsMode(mode);
  };

  const passesClearanceLevel = useCallback(
    (clearanceLevel: RoleLevelBoundary | ClearanceLevel | undefined) => {
      // If no clearance level is given, then is passes.
      if (clearanceLevel === undefined) return true;

      // If no user exists, then it does not pass.
      if (!user) return false;

      // If there is no role, then in does not pass
      if (!role) return false;

      // Finally, check if the user's level passes the clearance level.
      return role.level <= clearanceLevel;
    },
    [user, role]
  );

  const value = useMemo(
    () => ({
      user,
      isLoggedIn,
      getUserRoleLevel,
      isLoading: isLoading || roleIsLoading,
      handleLogin,
      handleLogout,
      passesClearanceLevel,
      teamsMode,
      setTeamsMode: handleSetTeamsMode,
    }),
    [user, isLoading, role, isLoading, teamsMode, passesClearanceLevel]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};
