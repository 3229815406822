export const BASE_URL =
  import.meta.env.VITE_BASE_URL ?? "https://api.necta.app/";

const API_BASE_URL = `api/`;

export const FINAL_BASE_URL = BASE_URL + API_BASE_URL;

/* ---------- TOKENS ---------- */
export const REFRESH_TOKEN_URL = `token/refresh/`;

/* ---------- USER ---------- */
export const USER_URL = `user/`;
export const ME_URL = `${USER_URL}me/`;
export const GET_ASSIGNABLE_ROLES_URL = `${USER_URL}get_assignable_roles/`;

export const getUserURL = (userId: string | number): string =>
  apiURL(`${USER_URL}${userId}/`);

/* ---------- AUTH ---------- */
export const LOGIN_URL = `login/`;
export const REGISTER_URL = `register/`;
export const TOKEN_URL = `token/`;
export const LOGOUT_URL = `${USER_URL}logout/blacklist/`;
export const REGISTER_ADMIN_URL = `${USER_URL}${REGISTER_URL}admin/`;
export const REGISTER_USER_URL = `${USER_URL}${REGISTER_URL}user/`;
export const SEND_EMAIL_VERIFICATION_URL = `${USER_URL}send_email_verification/`;
export const SEND_PASSWORD_RESET_URL = `${USER_URL}password_reset/`;

export const getActivateAccountURL = (uidb64: string, token: string): string =>
  apiURL(`${USER_URL}activate/${uidb64}/${token}/`);

export const getResetPasswordURL = (uidb64: string, token: string): string =>
  apiURL(`${USER_URL}reset-password/${uidb64}/${token}/`);

// Pusher auth
export const getPusherAuthEndpoint = () => apiURL("pusher_auth/");

/* ---------- AREA CODE ---------- */
export const AREA_CODE_URL = `area_code/`;

export const getAreaCodeURL = (areaCodeId: string | number): string =>
  apiURL(`${AREA_CODE_URL}${areaCodeId}/`);

export const getSubAreaCodesURL = (parentAreaCodeId: string | number): string =>
  apiURL(`${AREA_CODE_URL}${parentAreaCodeId}/get_sub_area_codes`);

/* ---------- CUSTOMER ---------- */
//   GET: List ALL customers within an organisation.
//  POST: Create a customer item.
export const CUSTOMER_URL = `customer/`;

export const getCustomerURL = (customerId: string | number): string =>
  apiURL(`${CUSTOMER_URL}${customerId}/`);

/* ---------- ORDER ---------- */
export const ORDER_URL = `order/`;
export const CURRENT_ORDERS_URL = `${ORDER_URL}list-current-orders/`;

export const getOrderURL = (orderId: string | number): string =>
  apiURL(`${ORDER_URL}${orderId}/`);

export const getOrderItemsURL = (orderId: string | number): string =>
  `${getOrderURL(orderId)}get_order_items/`;

/* ---------- ORDER ITEM ---------- */
export const ORDER_ITEM_URL = `order_item/`;

export const getOrderItemURL = (orderItemId: string | number): string =>
  apiURL(`${ORDER_ITEM_URL}${orderItemId}/`);

export const getOrderItemAssignedStockURL = (
  orderItemId: string | number
): string => `${getOrderItemURL(orderItemId)}get_assigned_stock/`;

export const getOrderItemAvailableStockURL = (
  orderItemId: string | number
): string => `${getOrderItemURL(orderItemId)}get_available_stock/`;

export const getOrderItemStockLinksURL = (
  orderItemId: string | number
): string => `${getOrderItemURL(orderItemId)}get_order_item_stock_links/`;

/* ---------- ORDER ITEM ---------- */
export const ORDER_ITEM_STOCK_LINK_URL = `order_item_stock_link/`;

export const getOrderItemStockLinkURL = (
  orderItemStockLinkId: string | number
): string => apiURL(`${ORDER_ITEM_STOCK_LINK_URL}${orderItemStockLinkId}/`);

export const getOrderItemStockLinkUsedStockHistoryURL = (): string =>
  apiURL(`${ORDER_ITEM_STOCK_LINK_URL}get_used_stock_history/`);

/* ---------- ZUPPLY ---------- */
export const ZUPPLY_SKU_MAPPINGS_URL = `zupply_sku_mapping/`;

/* ---------- PRODUCE ---------- */
//   GET: List ALL produce within an organisation.
//  POST: Create a produce item.
export const PRODUCE_URL = `produce/`;
export const PRODUCE_VARIETY_URL = `produce_variety/`;
export const PRODUCE_QUANTITY_SUFFIX_URL = `produce_quantity_suffix/`;
export const COMPOSITE_PRODUCE_COMPONENT_URL = `composite_produce_component/`;

export const getProduceURL = (produceId: string | number): string =>
  apiURL(`${PRODUCE_URL}${produceId}/`);

export const getProduceVarietiesURL = (produceId: string | number): string =>
  apiURL(`${PRODUCE_URL}${produceId}/get_varieties/`);

export const getProduceVarietyURL = (varietyId: string | number): string =>
  apiURL(`${PRODUCE_VARIETY_URL}${varietyId}/`);

export const getProduceQuantitySuffixesURL = (
  produceId: string | number
): string => apiURL(`${PRODUCE_URL}${produceId}/get_suffixes/`);

export const getProduceSuppliersURL = (produceId: string | number): string =>
  apiURL(`${PRODUCE_URL}${produceId}/get_suppliers/`);

export const getProduceQuantitySuffixURL = (
  quantitySuffixId: string | number
): string => apiURL(`${PRODUCE_QUANTITY_SUFFIX_URL}${quantitySuffixId}/`);

export const createCompositeProduceComponentURL = (
  id: string | number
): string => apiURL(`${PRODUCE_URL}${id}/create_component/`);

export const getCompositeProduceComponentURL = (id: string | number): string =>
  apiURL(`${COMPOSITE_PRODUCE_COMPONENT_URL}${id}/`);

export const getCompositeProduceComponentsURL = (
  produceId: string | number
): string => apiURL(`${PRODUCE_URL}${produceId}/get_components/`);

export const getProduceForCompositeProduceComponentURL = (
  produceId: string | number
): string => apiURL(`${PRODUCE_URL}${produceId}/list_produce_for_component/`);

export const getProduceOpenOrderItemsURL = (
  produceId: string | number
): string => apiURL(`${PRODUCE_URL}${produceId}/get_open_orders_details/`);

/* ---------- STOCK ---------- */
//   GET: List ALL stock within an organisation.
//  POST: Create a stock item.
export const STOCK_URL = `stock/`;
export const AVAILABLE_STOCK_URL = `${STOCK_URL}list_available_stock/`;

//   GET: Get a stock item.
//   PUT: Update stock item.
// PATCH: Partial update stock item.
//   DEL: Delete stock item.
// export const EDIT_STOCK_URL = `${STOCK_URL}/:stockId`;
export const getStockURL = (stockId: string | number): string =>
  apiURL(`${STOCK_URL}${stockId}/`);

export const getUsedStockHistoryURL = (stockId: string | number): string =>
  apiURL(`${STOCK_URL}${stockId}/get_used_stock_history/`);

//  POST: TODO
export const LIST_FILTERED_STOCK_URL = `${STOCK_URL}list_filtered/`;

/* ---------- SUPPLIER ---------- */
export const SUPPLIER_URL = `supplier/`;

export const getSupplierURL = (supplierId: string | number): string =>
  apiURL(`${SUPPLIER_URL}${supplierId}/`);

/* ---------- TEAM ---------- */
export const TEAM_URL = `team/`;

export const getTeamURL = (teamId: string | number): string =>
  apiURL(`${TEAM_URL}${teamId}/`);

export const getJoinTeamURL = (teamId: string | number): string =>
  apiURL(`${TEAM_URL}${teamId}/join/`);

export const getLeaveTeamURL = (teamId: string | number): string =>
  apiURL(`${TEAM_URL}${teamId}/leave/`);

/* ---------- DASHBOARD ---------- */
export const DASHBOARD_INFO_URL = `dashboard/`;

/* ---------- AI ---------- */
export const AI_QUERY_URL = `ai/`;

/* ---------- AUDIT TRAIL ---------- */
export const AUDIT_TRAIL_BASE = `audit_trail/`;
export const AUDIT_TRAIL_LIST = AUDIT_TRAIL_BASE;
export const AUDIT_TRAIL_DETAIL = (id: string | number) =>
  `${AUDIT_TRAIL_BASE}${id}`;
export const AUDIT_TRAIL_FOR_OBJECT = (contentType: string, objectId: number) =>
  `${AUDIT_TRAIL_BASE}?content_type=${contentType}&object_id=${objectId}`;

/* ---------- UTILITY FUNCTIONS ---------- */
export const getURL = (URL: string): string => {
  return `${API_BASE_URL}${URL}`;
};

// API
export const apiURL = (path: string): string => {
  return `${FINAL_BASE_URL}${path}`;
};

export const bulkDeleteAPIURL = (path: string): string => {
  return `${FINAL_BASE_URL}${path}bulk_delete/`;
};
