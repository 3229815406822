import Logo from "@/components/icons/logo";
import { ROUTES } from "@/constants/urls";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface AuthenticationWrapperProps {
  title: ReactNode;
  description: ReactNode;
  AuthenticationFormComponent?: React.FC;
  className?: string;
  children?: React.ReactNode;
}

export default function AuthenticationWrapper({
  title,
  description,
  AuthenticationFormComponent,
  className,
  children,
}: AuthenticationWrapperProps) {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate(ROUTES.LOGIN, { replace: true });
  };
  return (
    <div
      className={cn(
        "relative flex h-screen w-full flex-col items-center justify-center overflow-auto p-10",
        className
      )}
    >
      <div className="flex w-full max-w-lg flex-col">
        <div>
          <span className="flex w-full justify-center">
            <Logo clickable className="h-14 w-14" onClick={handleLogoClick} />
          </span>
          <div className="flex flex-col space-y-2 pt-4 text-center">
            <h1 className="text-2xl font-medium tracking-tight">{title}</h1>
            <p className="text-muted-foreground text-sm">{description}</p>
          </div>
        </div>
        {/* This div acts as a container for the form and any children. It grows to fill available space. */}
        <div className="grow pt-10">
          {AuthenticationFormComponent && <AuthenticationFormComponent />}
          {children}
        </div>
      </div>
    </div>
  );
}
