import { logOnDev } from "./response";
import { InternalAxiosRequestConfig } from "axios";

// Request Interceptor
const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { method, url } = config;

  logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Request`);

  if (method === "get") {
    config.timeout = 15000;
  }
  return config;
};

export default onRequest;
