import { KeyboardCode, ShortcutConfig } from "@/hooks/useKeyboardShortcut";

function getReadableKey(code: KeyboardCode | KeyboardCode[]): string {
  if (Array.isArray(code)) {
    return code.map(getReadableKey).join(" | ");
  }

  const specialKeys: { [key in KeyboardCode]?: string } = {
    Backspace: "⌫",
    Tab: "⇥",
    Enter: "↩",
    ShiftLeft: "Shift",
    ShiftRight: "Shift",
    ControlLeft: "Ctrl",
    ControlRight: "Ctrl",
    AltLeft: "Alt",
    AltRight: "Alt",
    Pause: "Pause",
    CapsLock: "Caps",
    Escape: "Esc",
    Space: "Space",
    PageUp: "PgUp",
    PageDown: "PgDn",
    End: "End",
    Home: "Home",
    ArrowLeft: "←",
    ArrowUp: "↑",
    ArrowRight: "→",
    ArrowDown: "↓",
    PrintScreen: "PrtSc",
    Insert: "Ins",
    Delete: "Del",
    Digit0: "0",
    Digit1: "1",
    Digit2: "2",
    Digit3: "3",
    Digit4: "4",
    Digit5: "5",
    Digit6: "6",
    Digit7: "7",
    Digit8: "8",
    Digit9: "9",
    Slash: "/",
    Backquote: "`",
    BracketLeft: "[",
    BracketRight: "]",
    Backslash: "\\",
    Semicolon: ";",
    Equal: "=",
    Comma: ",",
    Minus: "-",
    Period: ".",
  };

  return specialKeys[code] ?? code.replace("Key", "");
}

/**
 * A helper function to convert shortcut configuration to characters.
 * @param config the shortcut hook configuration.
 * @returns the readable string of shortcut characters.
 */
export const convertShortcutToCharacters = (
  config?: ShortcutConfig,
  options?: { withSeparator?: boolean }
): string => {
  if (!config) return "";

  const modifiers = [
    config.altKey ? "⌥" : "",
    config.shiftKey ? "⇧" : "",
    config.ctrlKey ? "^" : "",
  ].join("");

  const key = getReadableKey(config.code);

  if (options?.withSeparator) {
    return `${modifiers}${key}`.split("").join(" + ");
  }

  return `${modifiers}${key}`;
};

export const convertShortcutToCharactersArray = (
  config?: ShortcutConfig
): string[] => {
  if (!config) return [];

  const modifiersMap: { [key: string]: string } = {
    altKey: "⌥",
    shiftKey: "⇧",
    ctrlKey: "^",
  };

  const modifiers = Object.keys(modifiersMap).reduce<string[]>((acc, key) => {
    if (config[key as keyof ShortcutConfig]) {
      acc.push(modifiersMap[key]);
    }
    return acc;
  }, []);

  if (Array.isArray(config.code)) {
    return [...modifiers, ...config.code.map(getReadableKey)];
  }

  const key = config.code.replace("Key", "");

  return [...modifiers, key];
};
