import { ShortcutConfig } from "@/hooks/useKeyboardShortcut";
import { createContext } from "react";

export type RegisteredShortcut = {
  id: string;
  config: ShortcutConfig;
  onKeyDown?: () => void;
  onKeyUp?: () => void;
  setIsPressed?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  triggerOnKeyDown?: boolean;
};

export type KeyboardShortcutsContextType = {
  registerShortcut: (shortcut: RegisteredShortcut) => () => void;
  registerShortcuts: (shortcuts: RegisteredShortcut[]) => () => void;
};

export const KeyboardShortcutsContext =
  createContext<KeyboardShortcutsContextType>({
    registerShortcut: () => () => {},
    registerShortcuts: () => () => {},
  });
