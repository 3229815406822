import { currentOrderRoutes, defaultOrderRoutes } from "./orders";
import { produceRoutes } from "./produce";
import { settingsRoutes } from "./settings";
import { currentStockRoutes, defaultStockRoutes } from "./stock";
import { AppRouteType } from "./types";
import Loadable from "@/components/extended/loadable/loadable";
import { ClearanceLevel } from "@/constants/clearance-levels";
import { ROUTES, TableURL } from "@/constants/urls";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const routes: AppRouteType[] = [
  {
    name: "Dashboard",
    pathname: TableURL.HOME,
    element: Loadable(lazy(() => import("@/pages/dashboard"))),
    type: "item",
  },
  {
    name: "Current",
    pathname: TableURL.CURRENT,
    element: <Navigate to={TableURL.ORDERS} replace />,
    type: "section",
    children: [
      currentOrderRoutes,
      currentStockRoutes,
      // {
      //   name: <span className="font-logo">necta AI</span>,
      //   pathname: "ai",
      //   type: "item",
      //   clearanceLevel: ClearanceLevel.AI,
      //   element: Loadable(lazy(() => import("@/pages/ai"))),
      // },
    ],
  },
  // Tables
  {
    name: "Area Codes",
    pathname: TableURL.AREA_CODES,
    type: "item",
    element: Loadable(lazy(() => import("@/pages/tables/area-codes"))),
    children: [
      {
        name: "Edit Area Code",
        pathname: ":areaCodeId/*",
        type: "item",
        element: Loadable(lazy(() => import("@/pages/edit/area-code"))),
      },
    ],
  },
  {
    name: "Customers",
    pathname: TableURL.CUSTOMERS,
    type: "item",
    clearanceLevel: ClearanceLevel.CUSTOMERS,
    element: Loadable(lazy(() => import("@/pages/tables/customers"))),
    children: [
      {
        name: "Edit Customer",
        pathname: ":customerId",
        type: "item",
        element: Loadable(lazy(() => import("@/pages/edit/customer"))),
      },
    ],
  },
  defaultOrderRoutes,
  produceRoutes,
  defaultStockRoutes,
  {
    name: "Suppliers",
    pathname: TableURL.SUPPLIERS,
    type: "item",
    element: Loadable(lazy(() => import("@/pages/tables/suppliers"))),
    children: [
      {
        name: "Edit Supplier",
        pathname: ":supplierId",
        type: "item",
        element: Loadable(lazy(() => import("@/pages/edit/supplier"))),
      },
    ],
  },
  {
    name: "Teams",
    pathname: TableURL.TEAMS,
    type: "item",
    element: Loadable(lazy(() => import("@/pages/tables/teams"))),
    clearanceLevel: ClearanceLevel.TEAMS,
    children: [
      {
        name: "Edit Team",
        pathname: ":teamId",
        type: "item",
        element: Loadable(lazy(() => import("@/pages/edit/team"))),
      },
    ],
  },
  {
    name: "Users",
    pathname: TableURL.USERS,
    type: "item",
    clearanceLevel: ClearanceLevel.USERS,
    element: Loadable(lazy(() => import("@/pages/tables/users"))),
    children: [
      {
        name: "Edit User",
        pathname: ":userId",
        type: "item",
        element: Loadable(lazy(() => import("@/pages/edit/user"))),
      },
    ],
  },
  ...settingsRoutes,
  {
    name: "Help",
    pathname: ROUTES.HELP,
    clearanceLevel: ClearanceLevel.HELP,
    element: Loadable(lazy(() => import("@/pages/help"))),
    type: "item",
  },
  {
    name: "About",
    pathname: ROUTES.ABOUT,
    clearanceLevel: ClearanceLevel.ABOUT,
    element: Loadable(lazy(() => import("@/pages/about"))),
    type: "item",
  },
];

export default routes;
