import { listCurrentOrders } from "@/services/api/order/actions";
import { useQuery } from "@tanstack/react-query";

const MAX_ORDERS = 20;

export const CurrentOrdersLabel = () => {
  const { data: orders } = useQuery({
    queryKey: ["order", "list", "current"],
    queryFn: listCurrentOrders,
  });

  if (!orders || orders.count === 0) return null;

  return (
    <div className="text-secondary text-xs no-underline group-hover:no-underline">
      {orders.count >= MAX_ORDERS ? `${MAX_ORDERS}+` : orders.count}
    </div>
  );
};
