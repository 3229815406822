import { ClearanceLevel } from "@/constants/clearance-levels";
import { settingsNavigationItems } from "@/routing/dashboard/settings";
import { settingsNavigationShortcutConfig } from "@/services/shortcuts/navigation";
import {
  SidebarNavigationItemType,
  SidebarNavigationSectionType,
} from "@/types/navigation";
import { Cog, Workflow } from "lucide-react";

const settings = {
  type: "section",
  id: "settings-section",
  title: "Settings",
  href: "",
  icon: Cog,
  items: [
    {
      type: "group",
      id: "settings",
      title: "Settings",
      href: "/settings",
      icon: Cog,
      shortcutConfig: settingsNavigationShortcutConfig,
      items: [
        ...(settingsNavigationItems.map(item => ({
          ...item,
          type: "item",
          href: `/settings/${item.href}`,
          id: item.href,
        })) as SidebarNavigationItemType[]),
      ],
    },
    {
      id: "integrations",
      title: "Integrations",
      type: "item",
      href: "/integrations",
      clearanceLevel: ClearanceLevel.ORGANISATION,
      icon: Workflow,
    },
  ],
} satisfies SidebarNavigationSectionType;

export default settings;
