import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import assertField from "@/lib/utils/assert-field";
import { logOnDev } from "@/services/axios/interceptors/response";
import { useAuthenticationContext } from "@/services/context/authentication/authentication-context";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { LoaderCircle } from "lucide-react";
import { useForm } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { z } from "zod";

interface LoginErrorResponse {
  detail?: string;
}

export function LoginForm() {
  const { handleLogin } = useAuthenticationContext();
  const navigate = useNavigate();

  const schema = z.object({
    email: z.string().email("Please enter a valid email address."),
    password: z.string().min(8, "Password must be at least 8 characters."),
  });

  type LoginType = z.infer<typeof schema>;

  const defaultValues: LoginType = {
    email: "",
    password: "",
  } as const;

  const form = useForm<LoginType>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { formState } = form;

  const { isSubmitting } = formState;

  const handleOnSubmit = async (data: LoginType) => {
    try {
      await handleLogin(data);
    } catch (err) {
      logOnDev({ err });
      Sentry.captureMessage("Login Error", {
        extra: { err },
      });

      const axiosError = err as AxiosError;
      if (axiosError.response?.data) {
        if (axiosError.response.status === 429) {
          form.setError("root", {
            message: "Too many requests. Please try again later.",
          });
        } else {
          const errorData = axiosError.response.data as LoginErrorResponse;

          if (assertField(errorData, "detail")) {
            form.setError("root", { message: errorData.detail });
          } else {
            form.setError("root", {
              message: "Something went wrong. Please try again later.",
            });
          }
        }
      } else {
        form.setError("root", {
          message: "Something went wrong. Please try again later.",
        });
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      const email = z.string().email().parse(form.getValues("email"));
      navigate({
        pathname: "/password-reset",
        search: createSearchParams({
          email: email,
        }).toString(),
      });
    } catch (error) {
      navigate("/password-reset");
    }
  };

  return (
    <Form<LoginType> {...form}>
      <form
        onSubmit={form.handleSubmit(handleOnSubmit)}
        className="max-w-lg space-y-8"
      >
        <FormField
          key="email"
          control={form.control}
          name="email"
          render={({ field: formField }) => (
            <FormItem>
              <FormLabel className="w-fill flex justify-between leading-6">
                <span>Email</span>
                <Button
                  size={"sm"}
                  variant={"link"}
                  type="button"
                  className="h-auto p-0 leading-none hover:no-underline"
                  onClick={handleForgotPassword}
                >
                  Forgot your password?
                </Button>
              </FormLabel>
              <FormControl>
                <Input
                  {...formField}
                  type="email"
                  autoComplete="username"
                  autoCapitalize="none"
                  aria-autocomplete="inline"
                  disabled={isSubmitting}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          key="password"
          control={form.control}
          name="password"
          render={({ field: formField }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  {...formField}
                  type="password"
                  autoComplete="current-password"
                  aria-autocomplete="inline"
                  disabled={isSubmitting}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField name="root" render={() => <FormMessage />}></FormField>

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting && (
            <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
          )}
          Sign In
        </Button>
      </form>
    </Form>
  );
}
