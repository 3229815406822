import { cn, stringToHSL } from "@/lib/utils";
import { tw } from "@/lib/utils/tw";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const BASE_BADGE_CLASSNAME = tw`text-background focus:ring-ring inline-flex h-6 w-fit min-w-fit cursor-default items-center rounded-full border px-2.5 py-1 text-xs font-semibold whitespace-nowrap focus:ring-2 focus:ring-offset-2 focus:outline-hidden dark:brightness-110`;

const vars = {
  variants: {
    variant: {
      default: tw`bg-primary text-primary-foreground hover:bg-primary/80 border-transparent`,
      secondary: tw`text-secondary-foreground bg-secondary hover:bg-secondary/80 border-transparent`,
      destructive: tw`bg-destructive text-destructive-foreground hover:bg-destructive/80 border-transparent`,
      outline: tw`text-foreground h-auto rounded-2xl outline-hidden`,
      hash: tw`text-primary-foreground border-transparent`,
      skeleton: tw`bg-muted w-24 animate-pulse`,
      bare: tw`border-transparent`,
    },
    type: {
      default: "",
      clickable: tw`hover:cursor-pointer hover:ring-0 hover:outline-0 hover:outline-offset-0`,
    },
  },
  defaultVariants: {
    variant: "default",
    type: "default",
  },
} as const;
const badgeVariants = cva(BASE_BADGE_CLASSNAME, vars);

// Base type for common props
type BaseBadgeProps = React.HTMLAttributes<HTMLDivElement | HTMLButtonElement> &
  VariantProps<typeof badgeVariants>;

// Types for different variants
interface DefaultBadgeProps extends BaseBadgeProps {
  variant?: keyof (typeof vars)["variants"]["variant"];
}

interface HashBadgeProps extends BaseBadgeProps {
  variant: "hash";
  hash: string; // Required for hash variant
}

// Types for different types
interface DefaultTypeProps extends BaseBadgeProps {
  type?: "default";
}

interface ClickableTypeProps extends BaseBadgeProps {
  type: "clickable";
  href: string; // Required for clickable type
}

// Union type for all badge props
export type BadgeProps = (DefaultBadgeProps | HashBadgeProps) &
  (DefaultTypeProps | ClickableTypeProps);

function Badge({ className, variant, type, children, ...props }: BadgeProps) {
  let style = {};
  const navigate = useNavigate();

  // Handle hash variant
  if (variant === "hash") {
    const hash = (props as HashBadgeProps).hash;
    const { backgroundColor } = stringToHSL(hash);
    style = { backgroundColor, outlineColor: backgroundColor };
  }

  // Render clickable badge as an anchor tag
  if (type === "clickable") {
    const { href, ...restProps } = props as ClickableTypeProps;
    const handleOnClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(href);
    };

    return (
      <button
        onClick={handleOnClick}
        className={cn(
          badgeVariants({ variant, type }),
          className,
          "cursor-pointer"
        )}
        style={style}
        {...restProps}
        type="button"
      >
        {children}
      </button>
    );
  }

  // Render other types of badges
  return (
    <div
      className={cn(badgeVariants({ variant, type }), className)}
      style={style}
      {...props}
    >
      {variant === "skeleton" && <span>&nbsp;</span>}
      {children}
    </div>
  );
}

export { Badge, badgeVariants };
