import AuthenticationWrapper from "../authentication-wrapper";
import Necta from "@/components/extended/necta";
import { Button } from "@/components/ui/button";
import { Link } from "@/components/ui/link";
import { ROUTES } from "@/constants/urls";
import {
  activateAccount,
  sendNewAccountActivationEmail,
} from "@/services/api/auth/actions";
import { isAxiosError } from "axios";
import { CircleCheck, LoaderCircle, CircleSlash } from "lucide-react";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ERROR = {
  USER_NOT_FOUND:
    "We can't seem to find your account. Please check your email link or contact support.",
  USER_IS_VERIFIED: "It looks like you are already verified. Please sign in.",
  INVALID_TOKEN:
    "It looks like your link is no longer valid, please request a new email.",
} as const;

type Error = keyof typeof ERROR;
const DEFAULT_RELOCATE_SECONDS = 3;

const LoadingComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <LoaderCircle className="h-9 w-9 animate-spin" />
      <span>Please wait while we authenticate your account.</span>
    </div>
  );
};

const SuccessComponent = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(DEFAULT_RELOCATE_SECONDS);

  useEffect(() => {
    if (countdown === 0) {
      navigate(ROUTES.LOGIN, { replace: true });
      return;
    }

    const intervalId = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [countdown]);

  return (
    <div className="flex flex-col items-center justify-center gap-4 text-center">
      <CircleCheck className="text-primary h-9 w-9" />
      <span>
        Your email has been verified. Please <Link to={"/login"}>sign in</Link>{" "}
        to begin using <Necta />.
      </span>
      <span className="text-muted-foreground text-sm">
        You will be relocated to the login screen in {countdown} seconds.
      </span>
    </div>
  );
};

const ErrorComponent = ({
  error,
  uidb64,
}: {
  error: Error;
  uidb64: string;
}) => {
  const navigate = useNavigate();
  const [fullMessage, setFullMessage] = useState<ReactNode | null>(null);
  const [newEmailMessage, setNewEmailMessage] = useState<string | null>(null);

  const handleNewEmail = async () => {
    // TODO
    await sendNewAccountActivationEmail({ uidb64 })
      .then(() => {
        setNewEmailMessage(
          "We have sent you a new email. Please check your inbox."
        );
      })
      .catch(() => {
        setNewEmailMessage("An error occured, please try again later.");
      });
  };

  useEffect(() => {
    switch (error) {
      case "INVALID_TOKEN":
        setFullMessage(
          <>
            <span>{ERROR[error]}</span>
            <Button variant="outline" onClick={handleNewEmail}>
              Send a new email
            </Button>
          </>
        );
        break;

      case "USER_IS_VERIFIED":
        setFullMessage(<span>{ERROR[error]}</span>);
        setTimeout(
          () => navigate(ROUTES.LOGIN, { replace: true }),
          DEFAULT_RELOCATE_SECONDS * 1000
        );
        break;

      case "USER_NOT_FOUND":
        setFullMessage(<span>{ERROR[error]}</span>);
        break;

      default:
        /* @ts-ignore TODO: change ts rules to no check underscore variables */
        const _exhauntedCheck: never = error;
        break;
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-4 text-center">
      <CircleSlash className="h-9 w-9 text-red-500" />
      {fullMessage}
      <span className="text-muted-foreground text-sm">{newEmailMessage}</span>
    </div>
  );
};

const COMPONENTS = {
  LOADING: LoadingComponent,
  SUCCESS: SuccessComponent,
  ERROR: ErrorComponent,
} as const;

type ComponentKeys = keyof typeof COMPONENTS;

export default function VerificationPage() {
  const { uidb64 = "", token = "" } = useParams<{
    uidb64: string;
    token: string;
  }>();
  const [component, setComponent] = useState<ComponentKeys>("LOADING");
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    (async () => {
      await activateAccount(uidb64, token)
        .then(() => {
          setComponent("SUCCESS");
          setError(null);
        })
        .catch(error => {
          if (!isAxiosError(error)) return;
          const errorCode: Error = error.response?.data.error_code;
          setComponent("ERROR");
          setError(errorCode);
        });
    })();
  }, []);

  const description = <span className="">Email verification</span>;
  return (
    <AuthenticationWrapper
      title={
        <span>
          <Necta /> verification
        </span>
      }
      description={description}
      className="mt-[30vh] justify-normal"
    >
      <div>
        {error ? (
          <ErrorComponent error={error} uidb64={uidb64} />
        ) : (
          React.createElement(COMPONENTS[component])
        )}
      </div>
    </AuthenticationWrapper>
  );
}
