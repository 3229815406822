import { useSidebar } from "@/components/ui/sidebar";
import { ROUTES, TableURL } from "@/constants/urls";
import { cn } from "@/lib/utils";
import { listCurrentOrders } from "@/services/api/order/actions";
import { useQuery } from "@tanstack/react-query";
import {
  Boxes,
  Home,
  Package,
  Settings,
  LucideIcon,
  PanelBottomOpen,
} from "lucide-react";
import { NavLink } from "react-router-dom";

const MAX_ORDERS = 20;

interface BottomNavItemProps {
  to: string;
  icon: LucideIcon;
  label: string;
  badge?: React.ReactNode;
}

const BottomNavItem = ({
  to,
  icon: Icon,
  label,
  badge,
}: BottomNavItemProps) => {
  const { setOpenMobile } = useSidebar();

  const handleClick = () => {
    setOpenMobile(false);
  };

  return (
    <NavLink
      to={to}
      onClick={handleClick}
      className={({ isActive }) =>
        cn(
          "relative flex flex-col items-center justify-center px-2 py-1 text-xs transition-all duration-200",
          {
            "text-primary": isActive,
            "text-muted-foreground hover:text-foreground": !isActive,
          }
        )
      }
    >
      {({ isActive }) => (
        <>
          <div className="relative">
            <Icon className={cn("mb-1 size-5 transition-all duration-200")} />
            {badge}
          </div>
          <span className={cn("transition-all duration-200")}>{label}</span>
          {isActive && (
            <span className="bg-primary absolute -bottom-1 left-1/2 h-1 w-1 -translate-x-1/2 rounded-full" />
          )}
        </>
      )}
    </NavLink>
  );
};

const OrdersBadge = () => {
  const { data: orders } = useQuery({
    queryKey: ["order", "list", "current"],
    queryFn: listCurrentOrders,
  });

  if (!orders || orders.count === 0) return null;

  const isOverMax = orders.count >= MAX_ORDERS;

  return (
    <div className="bg-secondary text-secondary-foreground absolute -top-1 left-2 flex h-4 w-fit items-center justify-center rounded-full p-1 text-xs font-medium">
      {isOverMax ? `${MAX_ORDERS}+` : orders.count}
    </div>
  );
};

export default function MobileBottomNav() {
  const { setOpenMobile } = useSidebar();

  return (
    <div
      className={cn(
        "bg-background/80 supports-[-webkit-backdrop-filter]:bg-background/20 supports-[backdrop-filter]:bg-background/20 fixed right-0 bottom-0 left-0 z-50 block h-auto backdrop-blur transition-transform duration-300 md:hidden"
      )}
    >
      <nav className="pb-safe flex h-full items-center justify-around px-2 pt-1">
        <BottomNavItem to={`/${ROUTES.DASHBOARD}`} icon={Home} label="Home" />

        <BottomNavItem
          to={`/${TableURL.CURRENT}/${TableURL.ORDERS}`}
          icon={Package}
          label="Orders"
          badge={<OrdersBadge />}
        />

        <div className="flex flex-col items-center justify-center">
          <button
            onClick={() => setOpenMobile(true)}
            className="flex items-center justify-center"
          >
            <PanelBottomOpen className="size-5" />
          </button>
          <span className="text-muted-foreground mt-1 text-xs">Menu</span>
        </div>

        <BottomNavItem
          to={`/${TableURL.CURRENT}/${TableURL.STOCK}`}
          icon={Boxes}
          label="Stock"
        />

        <BottomNavItem
          to={`/${ROUTES.SETTINGS}`}
          icon={Settings}
          label="Settings"
        />
      </nav>
    </div>
  );
}
