import Loadable from "@/components/extended/loadable/loadable";
import { ROUTES } from "@/constants/urls";
import { lazy } from "react";
import { LoaderFunctionArgs, Navigate, Route } from "react-router-dom";

type PasswordResetRoutes = {
  loginLoader: ({
    request,
  }: LoaderFunctionArgs<unknown>) => Promise<Response | null>;
};

const resetPasswordRoutes = ({ loginLoader }: PasswordResetRoutes) => {
  return (
    <Route path="/reset-password" loader={loginLoader}>
      <Route
        path=":uidb64/:token"
        element={Loadable(
          lazy(() => import("@/pages/authentication/reset-password"))
        )}
      />
      <Route
        path="success"
        element={Loadable(
          lazy(() => import("@/pages/authentication/reset-password/success"))
        )}
      />
      <Route
        index
        path="*"
        element={<Navigate replace to={"/" + ROUTES.LOGIN} />}
      />
    </Route>
  );
};

export default resetPasswordRoutes;
