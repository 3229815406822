import Necta from "@/components/extended/necta";
import Logo from "@/components/icons/logo";

const Loading = () => {
  return (
    <div className="bg-background flex h-screen w-screen flex-col items-center justify-center">
      <Logo className="h-16 w-16" />
      <Necta className="text-muted-foreground" />
    </div>
  );
};

export default Loading;
