import { SidebarNavigationSection } from "./section";
import { SidebarNavigationSectionType } from "@/types/navigation";

interface SidebarNavigationProps {
  items: SidebarNavigationSectionType[];
}

export function SidebarNavigation({ items }: Readonly<SidebarNavigationProps>) {
  return (
    <div className="flex h-fit flex-col justify-between">
      {items.map(section => (
        <SidebarNavigationSection section={section} key={section.id} />
      ))}
    </div>
  );
}
