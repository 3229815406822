import Loadable from "@/components/extended/loadable/loadable";
import { lazy } from "react";
import { LoaderFunctionArgs, Route } from "react-router-dom";

type PasswordResetRoutes = {
  loginLoader: ({
    request,
  }: LoaderFunctionArgs<unknown>) => Promise<Response | null>;
};

const passwordResetRoutes = ({ loginLoader }: PasswordResetRoutes) => {
  return (
    <Route path="/password-reset" loader={loginLoader}>
      <Route
        index
        element={Loadable(
          lazy(() => import("@/pages/authentication/password-reset"))
        )}
      />
      <Route
        path="success"
        element={Loadable(
          lazy(() => import("@/pages/authentication/password-reset/success"))
        )}
      />
    </Route>
  );
};

export default passwordResetRoutes;
