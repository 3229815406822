import { baseMenuShortcutConfig } from ".";
import { ShortcutConfig } from "@/hooks/useKeyboardShortcut";

export const aboutShortcutConfig: ShortcutConfig = {
  ...baseMenuShortcutConfig,
  code: "KeyA",
} as const;

export const dashboardShortcutConfig: ShortcutConfig = {
  ...baseMenuShortcutConfig,
  code: "KeyD",
} as const;

export const helpShortcutConfig: ShortcutConfig = {
  ...baseMenuShortcutConfig,
  code: "KeyH",
} as const;

export const settingsShortcutConfig: ShortcutConfig = {
  ...baseMenuShortcutConfig,
  code: "KeyS",
} as const;
